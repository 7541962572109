import React from 'react';
import PropTypes from 'prop-types';

import { nullable } from '../utils/propTypes';

import Form     from 'react-bootstrap/Form';

class NullableTextInput extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleChangeFromNull = this.handleChangeFromNull.bind(this);
    }

    handleChange(evt) {
        const { value } = evt.target;
        this.props.onChange(this.props.id, this.props.trimEnd ? value.replace(/\s+$/gu, '') : value);
    }

    handleKeyUp(evt) {
        if (evt.key === 'Enter') this.props.onEnter();
    }

    handleChangeFromNull() {
        this.props.onChange(this.props.id, '');
    }

    render() {
        let multiline = false;

        if (this.props.rows && this.props.rows > 1) {
            multiline = true;
        }

        let className = null;

        if (this.props.value === null && this.props.shadeOnNull === true) {
            className = 'input-shaded';
        }

        let strValue = this.props.value || this.props.placeholder, onClickHandler = null;
        const style = {
            ...this.props.style,
        };

        if (this.props.value === null && this.props.messageOnNull) {
            strValue = this.props.messageOnNull
            onClickHandler = this.handleChangeFromNull;
            if (!this.props.shadeOnNull) style.color = '#ccc';
        }

        return (
            <Form.Control
                className = { className }
                as        = { multiline ? 'textarea' : 'input' }
                rows      = { multiline ? this.props.rows : null }
                onClick   = { onClickHandler }
                onChange  = { this.handleChange }
                onKeyUp   = { this.props.onEnter ? this.handleKeyUp : undefined}
                onBlur    = { this.props.onBlur }
                value     = { strValue }
                isValid   = { this.props.isValid || null }
                isInvalid = { this.props.isInvalid ||  null }
                disabled  = { this.props.disabled }
                style     = { style }
            />
        );
    }
}

NullableTextInput.propTypes = {
    id        : PropTypes.string.isRequired,
    value     : nullable(PropTypes.string).isRequired,
    rows      : PropTypes.number,
    onChange  : PropTypes.func,
    onEnter   : PropTypes.func,
    onBlur    : PropTypes.func,
    isValid   : PropTypes.bool,
    isInvalid : PropTypes.bool,

    /**
     * If true, any trailing white space entered is trimmed immediately.
     */
    trimEnd   : PropTypes.bool,

    /**
     * Will display this message string if the value is null. In addition, when
     * the input is clicked, the value will become the empty string '' and
     * the message string will be cleared.
     */
    messageOnNull: PropTypes.string,

    /**
     * Will visually shade the input if the value is null
     */
    shadeOnNull : PropTypes.bool,

    style: PropTypes.object,

    disabled: PropTypes.bool,

    placeholder: PropTypes.string,
};

NullableTextInput.defaultProps = {
    style: {},
    placeholder: '',
};

export { NullableTextInput };
