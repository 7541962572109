class ExtMap extends Map {
    getDefault(key, useDefault) {
        let value = this.get(key);

        if (value !== undefined) return value;

        this.set(key, useDefault);

        return useDefault;
    }

    // Get and set in an atomic operation
    getSet(key, value, useDefault) {
        let previous = this.get(key);
        this.set(key, value);

        return previous === undefined ? useDefault : previous;
    }

    getDelete(key) {
        const v = this.get(key);
        this.delete(key);

        return v;
    }
}

module.exports = { ExtMap };
