import React  from 'react';

import { observer } from 'mobx-react';

import Modal    from 'react-bootstrap/Modal';
import Button   from 'react-bootstrap/Button';

import { useAuth } from '../hooks/auth';

const AuthExpiryWarning = observer(() => {
    const authStore = useAuth();

    const refresh = () => {
        authStore.refreshUserAsync()
        .then(() => {
            console.log('[AuthExpiryWarning] user:', authStore.user);
        })
        .catch(err => {
            console.error(err);
        });
    };

    return <Modal
        show={authStore.timeoutWarn}
        centered
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header><Modal.Title>You&apos;re about to be logged out</Modal.Title></Modal.Header>
        <Modal.Body>
            Do you want to continue the session?
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant='info'
                onClick={() => refresh()}
            >Continue</Button>
        </Modal.Footer>
    </Modal>;
});

export { AuthExpiryWarning };
