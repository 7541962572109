/* eslint-disable react/prop-types */

import React  from 'react';

import { Spinner, Button } from 'react-bootstrap';

import { observer } from 'mobx-react';

import { orderSchema } from '../../schema/schema';

import { FormElement } from '../general/FormElement';
import { NullableTextInput } from '../general/TextInput';
import { StringSelect } from '../general/StringSelect';

import { Validated } from '../validated/Validated';

import { OrderItemLines } from './OrderItemEdit';

import { dateToDMY2 } from '../../store/OrderStore';

const orderTypeOpts = ['1ST BREAK', '2ND BREAK'];

const OrderEdit = observer(({ orderStore, api, onError, onClose }) => {
    const [itemData, setItemData] = React.useState(null);
    const [schoolList, setSchoolList] = React.useState([]);
    const [allergyList, setAllergyList] = React.useState([]);
    const [orderValue, setOrderValue] = React.useState({
        school: '',
        customer: '',
        orderType: orderTypeOpts[0],
        items: [],
        dateStr: dateToDMY2(new Date()),
    });
    const [allValid, setAllValid] = React.useState(false);
    const invalidSet = React.useRef(new Set([]));

    React.useEffect(
        () => {
            api.axios.get('/orders/items').then(res => {
                const itemMap = new Map();
                const items = [];

                for (const item of res.data) {
                    itemMap.set(item.name, item);
                    items.push(item.name);
                }

                setItemData({ items, itemMap });
            })
            .catch(err => {
                onError(err);
            });

            api.axios.get('/orders/schools').then(res => {
                setSchoolList(res.data);
            })
            .catch(err => {
                onError(err);
            });

            api.axios.get('/orders/allergies').then(res => {
                setAllergyList(res.data);
            })
            .catch(err => {
                onError(err);
            });

            return () => {};
        }, []
    );

    const handleInitialValidation = (id, validation) => {
        if (validation.valid) invalidSet.current.delete(id);
        else invalidSet.current.add(id);
        setAllValid(invalidSet.size === 0);
    };

    const handleChange = (id, value, invalid) => {
        if (invalid) invalidSet.current.add(id);
        else invalidSet.current.delete(id);
        setAllValid(invalidSet.current.size === 0);

        setOrderValue(
            prev => ({
                ...prev,
                [id]: value
            })
        );
    };

    const handleSubmit = () => {
        const data = {
            ...orderValue,
            allergies: orderValue.allergies ? orderValue.allergies.join(',') : '',
        };

        orderStore.addManualOrderAsync(data)
        .then(onClose)
        .catch(err => {
            onError(err);
        });
    };

    if (orderStore.loading || !itemData) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    return (
        <div style={{ marginTop: '1em', paddingRight: '2em' }} >
            <Validated
                id='school'
                label = 'School'
                schema = {orderSchema}
                value = {orderValue.school}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <StringSelect {...props} options={schoolList} />}
                />}
            />
            <Validated
                id='customer'
                label = 'Customer'
                schema = {orderSchema}
                value = {orderValue.customer}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <NullableTextInput {...props} />}
                />}
            />
            <Validated
                id='allergies'
                label = 'Allergies'
                schema = {orderSchema}
                value = {orderValue.allergies}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <StringSelect {...props} options={allergyList} isMulti={true} />}
                />}
            />
            <Validated
                id='orderType'
                label = 'Order Type'
                schema = {orderSchema}
                value = {orderValue.orderType}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <StringSelect {...props} options={orderTypeOpts} />}
                />}
            />
            <Validated
                id='items'
                label = 'Menu Items'
                schema = {orderSchema}
                value = {orderValue.items}
                onChange={handleChange}
                onLoad={handleInitialValidation}
                render = { props => <FormElement
                    {...props}
                    render={props => <OrderItemLines {...props} itemData={itemData} />}
                />}
            />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant='info' onClick={onClose} className='ms-2'>
                    Cancel & Close
                </Button>
                <Button variant='info' onClick={handleSubmit} className='ms-2' disabled={!allValid}>
                    Add Order
                </Button>
            </div>
        </div>
    );
});

export { OrderEdit }
