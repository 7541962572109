/* eslint-disable react/prop-types */

import React  from 'react';

const ScanInput = ({ onScan }) => {
    const barcodeRef = React.useRef('');

    const keyUpHandler = evt => {
        evt.preventDefault();

        if (evt.code === 'Enter') {
            document.activeElement.blur();
            const barcode = barcodeRef.current;
            barcodeRef.current = '';

            // Ignore very short barcodes as this likely means enter was pressed on keyboard
            // rather than an attempt to scan a barcode.
            if (barcode.length > 4) {
                onScan(barcode);
            }

        } else if (evt.key >= '0' && evt.key <= '9') { // Ignore special chars that usually map to longer string values
            barcodeRef.current = `${barcodeRef.current}${evt.key}`;
        }
    };

    React.useEffect(
        () => {
            document.body.addEventListener('keyup', keyUpHandler);

            return () => {
                document.body.removeEventListener('keyup', keyUpHandler);
            };
        }, []
    );
};

export { ScanInput };
