import React from 'react';
import {toJS} from 'mobx';
import { useAuth } from '../hooks/auth';

const UserInfo = () => {
    const authStore = useAuth();

    console.log(toJS(authStore.user));

    return <p><strong>User:</strong> {authStore.user.name}</p>;
};

export { UserInfo };
