const EventEmitter = require('events');

class ExtEmitter extends EventEmitter {

    emitDelayed(eventName, delayMs, ...args) {
        setTimeout(
            () => this.emit(eventName, ...args),
            delayMs
        );
    }
}

module.exports = { ExtEmitter }
