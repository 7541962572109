/* eslint-disable react/prop-types */

import React  from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Nav } from '../Nav';

import { withAuth } from '../hoc/withAuth';

import { UISettings } from '../../contexts/UISettings';

import { UserList } from './UserList';

class UserInner extends React.Component {
    constructor(props) {
        super(props);

        this.state = { userPermissions: [], page: 'Account' };
    }

    componentDidMount() {
        try {
            const user = this.props.authStore.user;

            this.setState({ userPermissions: user.permissions });

        } catch (err) {
            this.props.onError(err);
        }
    }

    render() {
        //Depending on access levels:
        //- Password password
        //- Org user list (with create and delete)
        //- Set users Org (also depends on access to orgs)

        return (<>
            <Nav />
            <div className='mt-2' style={{ width: '100%', maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}>
                {
                    this.state.userPermissions.includes('readUsers')
                    ? <UserList api={this.props.api} />
                    : null
                }
            </div>
        </>);
    }
}

const Users = props => {
    return <UISettings.Consumer>{
        ({ pushError }) => <UserInner {...props} onError={pushError} />
    }</UISettings.Consumer>;
}

Users.propTypes = {
    api: PropTypes.object.isRequired,
};

const UsersWithRouter = withAuth(withRouter(Users));

export {
    UsersWithRouter as Users,
}
