import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

/**
 * A general handler for converting errors into state changes.
 */
class ErrorSetter extends React.Component {

    /**
     * Set an error state based on the given Error object.
     * Will set state.fatalError to an object with at least a message property.
     * @param {Error} An error object. Supports strings and Error instances.
     */
    setErrorState(error) {
        console.log('-- setErrorState ---------------------');
        console.trace();
        console.log(error);

        if (error.response) {
            if (error.response.status === 401) {
                const extra =  error.response.data && error.response.data.message ? ` - ${error.response.data.message}` : '';
                this.setState({ fatalError: { message: `API authentication failed${extra}. Log in again.`}});
            } else if (error.response.data && error.response.data.message) {
                this.setState({ fatalError: { message: `Response code: ${error.response.status} - ${error.response.data.message}` }});
            } else {
                this.setState({ fatalError: { message: `Response code: ${error.response.status} - ${error.toString()}` }});
            }
        } else if (error.data && error.data.message) {
            this.setState({ fatalError: { message: error.data.message }});
        } else {
            this.setState({ fatalError: { message: error.toString()}});
        }
    }

    checkError() {
        if (this.state.fatalError) {
            return (
                <Redirect to={{pathname: '/fatalError', state:{message:this.state.fatalError.message}}}/>
            );
        }
    }
}

class Error extends React.Component {
    render() {
        const message
            = this.props.location && this.props.location.state && this.props.location.state.message
            ? this.props.location.state.message
            : null;

        const dismiss = () => {
            this.props.history.push('/login')
        };

        return (
            <Alert variant='danger' className='mw-content mx-auto mt-3'>
                <Alert.Heading>Application error.</Alert.Heading>
                <hr />
                <p>
                    {message ? <>{message}<br/></> : null}
                    See logs for details.
                </p>
                <div className='text-end'>
                    <Button onClick={dismiss} variant='danger'>
                        Back to login
                    </Button>
                </div>
            </Alert>
        )
    }
}

Error.propTypes = {
    // location and history come from withRouter HOC
    location: PropTypes.isRequired,
    history: PropTypes.isRequired
};

// Wrap Error Component so that history is available
// withRouter is a 'higher order component'
// https://reactjs.org/docs/higher-order-components.html

const ErrorWithRouter = withRouter(Error);

export { ErrorWithRouter as Error, ErrorSetter };
