class Mutex {
    constructor() {
        this.locked = false;

        this.waiting = [];
    }

    _waitInQueue() {
        return new Promise( resolve => {
            const done = () => resolve();
            this.waiting.push(done);
        });
    }

    async asyncLock() {
        if (this.locked) await this._waitInQueue();
        this.locked = true;

        return () => {
            const next = this.waiting.shift();

            if (next) {
                // stays locked, but next will receive the lock
                next();
            } else {
                this.locked = false;
            }
        }
    }
}

module.exports = { Mutex };
