/* eslint-disable react/prop-types */

import React  from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, Offcanvas } from 'react-bootstrap';

import { UsersStore } from '../../store/UserStore';

import { SubmitButton } from '../general/SubmitButton';
import { ReactComponent as BinSVG } from '../../assets/delete.svg';
import { ReactComponent as AddUserSVG } from '../../assets/person_add.svg';

import { UserDataGrid } from './UserDataGrid';
import { UserEdit } from './UserEdit';

class UserList extends React.Component {
    constructor(props) {
        super(props);

        this.state = { selected: new Set() };

        this.usersStore = new UsersStore(props.api);

        this.handleAdd = this.handleAdd.bind(this);
        this.handleDeleteAsync = this.handleDeleteAsync.bind(this);
        this.handleCreateAsync = this.handleCreateAsync.bind(this);
    }

    componentDidMount() {
        try {
            this.usersStore.load();

        } catch (err) {
            this.setErrorState(err);
        }
    }

    handleAdd() {
        this.setState({ editUser: true, userId: null });
    }

    async handleDeleteAsync() {
        try {
            if (this.state.selected.size) {
                await this.usersStore.deleteUsers(this.state.selected);

                this.setState({ selected: new Set() });

                return true;
            }

        } catch (err) {
            console.log(err);
        }

        return false;
    }

    async handleCreateAsync(user) {
        try {
            if (this.state.userId) {
                await this.usersStore.updateUser(this.state.userId, user);

            } else {
                await this.usersStore.createUser(user);
            }

            this.setState({ editUser: false, userId: null });

            return true;

        } catch (err) {
            console.log(err);
        }

        return false;
    }

    render() {
        if (this.state.fatalError) {
            return (
                <Redirect to={{pathname: '/fatalError', state:{message:this.state.fatalError.message}}}/>
            );
        }

        //Depending on access levels:
        //- Password password
        //- Org user list (with create and delete)
        //- Set users Org (also depends on access to orgs)

        return (<>
            <div className='text-end pt-2 pb-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5 style={{ borderBottom: 'solid 1px #ccc', width: 'calc(100% - 14rem)'}}>User Management</h5>
                <div>
                    <Button variant='info' onClick={this.handleAdd} className='ms-2'>
                        <AddUserSVG style={{height: '1.2rem'}} alt='Add user'/>
                    </Button>
                    &nbsp;
                    <SubmitButton
                        variant={'danger'}
                        onSubmit={this.handleDeleteAsync}
                        body='Really delete selected users?'
                        id='deleteUsers'
                    >
                        <BinSVG style={{height: '1.2rem'}} alt='Delete selected'/>
                    </SubmitButton>
                </div>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <UserDataGrid
                    usersStore={this.usersStore}
                    onSelectChange={selectedRows => {
                        this.setState({ selected: selectedRows });
                    }}
                    onUserClick={userId => this.setState({ userId, editUser: true })}
                />
            </div>
            <Offcanvas show={this.state.editUser} placement='bottom' onHide={() => this.setState({ editUser: false })} className='h-50'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{this.state.userId ? 'Edit' : '+'} User</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {
                        this.state.userId
                        ? <UserEdit
                            onClose={() => this.setState({ editUser: false })}
                            onSubmit={this.handleCreateAsync}
                            defaultRoles={this.usersStore.defaultRoles}
                            assignableRoles={this.usersStore.assignableRoles}
                            initialValues={this.usersStore.getUser(this.state.userId)}
                        />
                        : <UserEdit
                            onClose={() => this.setState({ editUser: false })}
                            onSubmit={this.handleCreateAsync}
                            defaultRoles={this.usersStore.defaultRoles}
                            assignableRoles={this.usersStore.assignableRoles}
                        />
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>);
    }
}

UserList.propTypes = {
    api: PropTypes.object.isRequired,
};

const UserListWithRouter = withRouter(UserList);

export {
    UserListWithRouter as UserList,
}
