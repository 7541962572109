/* eslint-disable react/prop-types */

import React  from 'react';

import { Spinner, Button } from 'react-bootstrap';

import { observer } from 'mobx-react';

import { DataTable } from '../table/DataTable';

const UserDataGrid = observer(({ usersStore, onSelectChange, onUserClick }) => {
    if (usersStore.loading) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    const handleSelectedChange = rows => {
        console.log(rows);
        onSelectChange(rows);
    }

    const Name = props => <div>{
        onUserClick
        ? <Button
            variant='link'
            onClick={() => onUserClick(props.rowData.userId)}
            style={{ padding: 0 }}
        >{props.value}</Button>
        : props.value
    }</div>;

    return (
        <div style={{ width: '100%' }}>
            <DataTable
                schema={{
                    type: 'object',
                    properties: {
                        name: { type: 'string', title: 'Name' },
                        email: { type: 'string', title: 'Email' },
                        numRoles: { type: 'integer', title: 'Roles' },
                    }
                }}
                decorate={false}
                borders={false}
                noDataMessage='No Users'
                data={usersStore.users}
                onSelect={handleSelectedChange}
                uniqueIdField='userId'
                headerRowHeight={70}
                customCells={{
                    name: { component: <Name /> }
                }}
            />
        </div>
    );
});

export {
    UserDataGrid,
}
