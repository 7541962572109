import React from 'react';
import PropTypes from 'prop-types';

//import { toJS } from 'mobx';

import Table    from 'react-bootstrap/Table';
import Form     from 'react-bootstrap/Form';

import { UpDownSelector } from '../general/UpDownSelector';

import { asStringCompare } from '../utils/compare';
import { textFilter } from '../utils/filter';

import { set } from 'node-utils';

import rowTypes from './rowTypes';
import { insertCellsToTds } from './dataTableUtils';

import { FilterRow } from './FilterRow';
import { DataTableRow } from './DataTableRow';

function sort(dataToSort, rowMap, customCells, field, order) {
    dataToSort.sort((_aId, _bId) => {
        const [aId, bId] = order === 'up' ? [_bId, _aId] : [_aId, _bId];

        const a = rowMap.get(aId), b = rowMap.get(bId);

        if (customCells[field] && customCells[field].compareFn) {
            return customCells[field].compareFn(a[field], b[field], aId, bId);

        } else if (typeof a[field] === 'string' && typeof b[field] === 'string') {
            if (!a[field] && b[field]) return 1;
            if (!b[field] && a[field]) return -1;

            return a[field].localeCompare(b[field]);

        } else if (typeof a[field] === 'number' && typeof b[field] === 'number') {
            return a[field] - b[field];
        }

        console.log('DataTable sort: Falling back to asStringCompare');

        return asStringCompare(a[field], b[field]);
    });

    return { sortBy: field, sortDir: order };
}

function checkFilter(rowData, filters, customFilter, customCells) {
    if (customFilter) {
        const customFilterResult
            = customFilter.reduce((accumulator, filter) => (accumulator && filter(rowData)), true);

        if (!customFilterResult) return false;
    }

    for (const filterField of Object.keys(filters)) {
        if (filters[filterField] === null) continue;

        let value = rowData[filterField];

        let filterFn = textFilter;

        if (
            customCells
            && customCells[filterField]
            && customCells[filterField].filterFn
        ) {
            filterFn = customCells[filterField].filterFn;

        } else if (value === undefined || value === null) {
            return false;

        } else {
            value = rowData[filterField].toString();
        }

        if (!filterFn(filters[filterField], value, rowData)) return false;
    }

    return true;
}

// Apply the filters and return the newly calculated state changes.
function calcFilterVisibleState(rowMap, uniqueIdField ,filters, customFilter, customCells, prevVisibleSet, checkedSet, onSelect) {
    // eslint-disable-next-line
    const prevSelected = set.setIntersection(checkedSet, prevVisibleSet).size;
    const newVisibleSet = new Set(), selected = new Set();

    // TODO: How can this be optimised? n^2 worst case
    for (const rowData of rowMap.values()) {
        if (checkFilter(rowData, filters, customFilter, customCells)) {
            // Force string identifiers because these are compared against
            // html attribute values
            const id = rowData[uniqueIdField].toString();
            newVisibleSet.add(id);
            if (checkedSet.has(id)) selected.add(id);
        }
    }

    // Some checked options may be invisible, so update the user.
    if (onSelect) {
        //const selected = set.setIntersection(checkedSet, newVisibleSet);
        // Only update if there are actually checked options that are
        // hidden, or if there are checked options that have become visible.
        if (checkedSet.size > selected.size || prevSelected !== selected.size) onSelect(selected);
    }

    return newVisibleSet;
}

////////////////////////////////////////////////////////////////////////////////

const DataTable = p => {
    const [visibleSet, setVisibleSet] = React.useState(new Set());
    const [selectAll, setSelectAll] = React.useState(false);
    const [checkedSet, setCheckedSet] = React.useState(new Set());
    const [sortState, setSortState] = React.useState({ sortBy: p.initialSort, sortDir: p.initialSortDir });
    const filterRef = React.useRef({});

    function handleSelect(evt) {
        const {id, checked} = evt.target;

        // Don't do anything async until we call setCheckedSet to avoid any issues
        // with state updates being skipped.
        setCheckedSet(
            prevChecked => {
                let nowChecked = null;

                if (id === 'select_all') {
                    const rowWithCheckSet = set.setDifference(visibleSet, [...p.noSelect].map(e => e.toString()));

                    setSelectAll(checked);

                    if (checked) nowChecked = set.setUnion(prevChecked, rowWithCheckSet);
                    else nowChecked = set.setDifference(prevChecked, visibleSet);

                } else {
                    const rowId = id.slice(7); // relies on select_${id} naming convention from above
                    if (checked) nowChecked = set.setUnion(prevChecked, [rowId]);
                    else nowChecked = set.setDifference(prevChecked, [rowId]);

                    if (!checked) setSelectAll(false);
                }

                p.onSelect(set.setIntersection(nowChecked, visibleSet));

                return nowChecked;
            }
        );

    }

    const setupHeaders = (groupDefs, fieldDefs) => {
        if (!groupDefs) {
            return {
                groups: null,
                headers: new Map(
                    [...Object.entries(fieldDefs)].map(([key, field]) => [key, field.title || key])
                ),
                fields: Object.keys(fieldDefs),
            };
        }

        const groups = [], headers = new Map(), orderedFields = [];

        for (const group of groupDefs) {
            let nCols = 0;

            for (const [key, field] of Object.entries(fieldDefs)) {
                if (field.group !== group.key) continue;

                nCols++;
                orderedFields.push(key);
                headers.set(key, field.title || key);
            }

            groups.push({ ...group, nCols });
        }

        return { groups, headers, fields: orderedFields };
    };

    /// Data setup - run when data or schema changes
    const {groups, headers, rowMap, sortedOrder, fields} = React.useMemo(() => {
        const rowMap = new Map();
        const sortedOrder = [];
        const nowVisible = new Set();

        const { groups, headers, fields } = setupHeaders(p.groups, p.schema.properties);

        const idSet = new Set();

        for (const row of p.data) {
            const id = row[p.uniqueIdField];
            idSet.add(id.toString());

            rowMap.set(id, row);
            sortedOrder.push(id);
            // Initially we completely reset the visible state
            nowVisible.add(id.toString());
        }

        // Remove any rows that no longer exist from the checked set
        setCheckedSet(prevChecked => {
            const newCheckedSet = set.setIntersection(prevChecked, idSet);
            setSelectAll(idSet.size > 0 && newCheckedSet.size === idSet.size);

            return newCheckedSet;
        });

        if (sortState.sortBy) {
            sort(sortedOrder, rowMap, p.customCells, sortState.sortBy, sortState.sortDir);
        }

        // Previously we did this conditionally, but think we should be able to just
        // always apply - keeping this code in case that doesn't work
        //if (this.props.customFilter || Object.entries(this.filters).length > 0) {
        //    filterState = this._calcFilterState();
        //}

        // Apply any filters again to redefine the visible set
        const filteredVisibleSet = calcFilterVisibleState(
            rowMap,
            p.uniqueIdField,
            filterRef.current,
            p.customFilter,
            p.customCells,
            nowVisible,
            checkedSet,
            p.onSelect
        );

        setVisibleSet(filteredVisibleSet);

        // if a filter is applied that results in any of the newly shown
        // rows being unchecked, select all needs to be unchecked.
        if (set.setDifference(filteredVisibleSet, checkedSet).size > 0) {
            setSelectAll(false);
        }

        return { groups, headers, rowMap, sortedOrder, fields };
    }, [p.schema, p.data, p.customFilter]);

    /// Column filter functionality ////////////////////////////////////////////

    const handleFilterChange = (id, value) => {
        // Record active filter state in case the data changes and we need to re-apply
        // Support string filter values and arrays.
        filterRef.current = {
            ...filterRef.current,
            [id]: (value === '' || value?.length === 0) ? null : value
        };
        setVisibleSet(prevVisibleSet => {
            const visibleSet = calcFilterVisibleState(
                rowMap,
                p.uniqueIdField,
                filterRef.current,
                p.customFilter,
                p.customCells,
                prevVisibleSet,
                checkedSet,
                p.onSelect
            );

            if (set.setDifference(visibleSet, checkedSet).size > 0) setSelectAll(false);

            if (p.onFilter) p.onFilter([...visibleSet]);

            return visibleSet;
        });
    }

    /// Column sort functionality //////////////////////////////////////////////

    const handleSort = (field, order) => {
        if (sortState.sortBy === field && sortState.sortDir === order) return;

        setSortState(
            sort(sortedOrder, rowMap, p.customCells, field, order)
        );

        if (p.onSort) p.onSort(field, order);
    }

    /// Rendering  /////////////////////////////////////////////////////////////

    function _renderRow(rowData) {
        const uniqueId = rowData[p.uniqueIdField];

        return (
            <DataTableRow
                key         = { uniqueId }
                rowKey      = { uniqueId }
                fields      = { fields }
                rowData     = { rowData }
                schema      = { p.schema }
                insertCells = { p.insertCells }
                insertCellsAfter = { p.insertCellsAfter }
                customCells = { p.customCells }
                onSelect    = { typeof p.onSelect === 'function' ? handleSelect : null }
                isSelected  = { checkedSet.has(uniqueId.toString()) }
                noSelect    = { p.noSelect.has(uniqueId) }
                onChange    = { p.handleChange }
            />
        );
    }

    const sortFields = p.sortFor ? new Set(p.sortFor) : null;

    const groupRow
        = groups
        ? groups.map(
            group => <td
                key={group.key}
                colSpan={group.nCols}
                className='pt-1 pb-1'
                style={{
                    verticalAlign: 'bottom',
                    minHeight: '1.9em',
                    height: p.headerRowHeight ? `${p.headerRowHeight}px` : undefined,
                }}
            >
                <div style={{display: 'flex', alignContent: 'baseline', whiteSpace: 'nowrap'}}>
                    <strong>{ group.title }</strong>
                </div>
            </td>
        )
        : null;

    // Only need to set padding-top on one td to modify the baseline for the full row
    //
    // If no borders, place the up/down arrow next on the left of the title
    const headerRow = fields.map( field => (
        <td
            key={field}
            className='pt-1 pb-1'
            style={{
                verticalAlign: 'bottom',
                width: p.widths && p.widths[field] ? p.widths[field] : 'auto',
                minHeight: '1.9em',
                height: p.headerRowHeight ? `${p.headerRowHeight}px` : undefined,
            }}
        >
            <div style={{display: 'flex', alignContent: 'baseline', whiteSpace: 'nowrap'}}>
                <strong>{ headers.get(field) }</strong>
                {
                    (!sortFields || sortFields.has(field))
                    ? <div style={{marginLeft: 'auto', verticalAlign: 'baseline'}}>
                        <UpDownSelector
                            selected={sortState.sortBy === field ? sortState.sortDir : null}
                            onClick={order => handleSort(field, order)}
                        />
                    </div>
                    : null
                }
            </div>
        </td>
    ));

    const theadInsert = insertCellsToTds(p.insertCells, 'all', rowTypes.header);
    const theadInsertAfter = insertCellsToTds(p.insertCellsAfter, 'all', rowTypes.header);

    const dataRows = sortedOrder.reduce( (accumulator, id) => {
        const rowData = rowMap.get(id);
        if (visibleSet.has(id.toString())) accumulator.push(_renderRow(rowData));

        return accumulator;
    }, []);

    return (<div style={{ width: '100%' }}>
        <Table striped={p.decorate} bordered={p.borders} hover size='sm' style={{ fontSize: '14px' }}>
            <thead>
                {
                    groupRow
                    ? <tr>{groupRow}</tr>
                    : null
                }
                <tr>
                    { p.onSelect
                        ? <td style={{width: '3em'}}>
                            <Form.Check
                                type      = 'checkbox'
                                id        = 'select_all'
                                checked   = { selectAll }
                                onChange  = { handleSelect }
                            />
                        </td>
                        : null
                    }
                    { theadInsert }
                    { headerRow }
                    { theadInsertAfter }
                </tr>
                { p.filterFor
                    ? <FilterRow
                        rowKey='filter'
                        fields={fields}
                        initialValues={p.filterDefaults}
                        schema={p.schema}
                        onChange={handleFilterChange}
                        filterFor={p.filterFor}
                        customCells={p.customCells}
                        insertCells={p.insertCells}
                        insertCellsAfter={p.insertCellsAfter}
                        hasSelectColumn={typeof p.onSelect === 'function'}
                    />
                    : null
                }
            </thead>
            <tbody>
                { dataRows.length > 0 ? dataRows : null }
            </tbody>
        </Table>
        { dataRows.length > 0 // FIXME: Supported loading prop?|| !this.state.loaded
            ? null
            : <>
                <Table
                    striped={p.decorate}
                    bordered={p.decorate || p.borders}
                    size='sm'
                    style={{ marginTop: '-1rem' /* move up to abutt with other table */ }}
                >
                    <tbody>
                        <tr><td>{
                            rowMap.size > 0
                            ? p.noDataFilterMessage || p.noDataMessage
                            : p.noDataMessage
                        }</td></tr>
                    </tbody>
                </Table>
            </>
        }
    </div>);

};

DataTable.propTypes = {

    schema : PropTypes.object.isRequired,

    /**
     * Specifies heading groups. The groups will be laid out in order left to
     * right and the child columns reordered to sit below the group headers.
     * Child columns are placed in groups by including a group property on
     * the column schema, with value matching the group key.
     */
    groups: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string,
    })),

    data : PropTypes.array.isRequired,

    uniqueIdField: PropTypes.string.isRequired,

    /**
     * A string displayed if there is no data in the set.
     */
    noDataMessage : PropTypes.string,

    /**
     * A string displayed if there is no data to display due to filtering.
     */
    noDataFilterMessage : PropTypes.string,

    /**
     * Widths of the columns.
     * Each width is defined using a property on the object, where
     * the property name matches a field name on the model.
     */
    widths : PropTypes.object,

    /**
     * Which columns of the table show a filter control.
     *
     * The value is an array of strings, with each value corresponding to
     * a column name.
     * For the columns given, a filter component will be rendered in the filter
     * row - a row beneath the header row and before the first data row.
     * The value of the filter is input to a filter function that determines
     * whether the row will be hidden.
     * The the standard filter function looks for 'dataValue' as a
     * substring of 'filterValue'.
     *
     * The filters for each column are combined using a logically AND, that is
     * the filter for every column must return true for the row to be visible.
     */
    filterFor : PropTypes.array,

    filterDefaults: PropTypes.object,

    /**
     * Which columns of the table show a sort control. If this prop is
     * undefined, then all columns will show a sort control.
     *
     * The value is an array of strings, with each value corresponding to
     * a column name.
     */
    sortFor : PropTypes.array,

    /**
     * The initial sort field. If not specified, the table will be in
     * the order provided by the model.
     */
    initialSort : PropTypes.string,

    /**
     * The initial sort direction. Only valid if initialSort is also set.
     */
    initialSortDir : PropTypes.oneOf(['up', 'down']),

    /**
     * A handler that allows the user to know when a column sort occurs.
     * Must have the signature:
     * (String fieldName, String direction) => undefined
     */
    onSort : PropTypes.func,

    /**
     * A handler that allows the user to know when a filter change occurs.
     * Will return the set of visible IDs after the filter is applied.
     * Must have signature:
     * (Array ids) => undefined
     */
    onFilter: PropTypes.func,

    /**
     * An object containing custom data cell definitions. The custom data cells
     * will be added to each row of the table.
     * Each custom data cell is defined using a property on the object, where
     * the property name matches a data field on the model.
     *
     * The custom data cell definition is an object with the properties:
     * - component (required)
     *   A React component that will render the data. Must accept the value
     *   and id props.
     *
     * - filterComponent (optional)
     *   A React component used in the filter row of the table. Must accept
     *   onChange (with sig: (id,value) => {} ) and id fields. id will match
     *   the field name of the column being rendered.
     *
     * - filterFn (optional)
     *
     *   Must have the signature:
     *   (filterValue, dataValue, rowData) => Boolean
     *
     *   Where filterValue is the value of the filter component for the column.
     *   dataValue is the value of the cell in the row/column being checked.
     *   rowData is the entire row being filtered (for access to additional
     *   properties).
     *
     *   filterFn is a custom function that ban be provided if the standard filter
     *   is not sufficient.
     *
     *   The filter function is called for each cell that is in a column
     *   where the filter value is not empty. The filter function determines
     *   whether a row is visisble or not.
     *
     * - compareFn (optional)
     *
     *   Must have the signature:
     *   (a, b, aId, bId) => Number
     *
     *   Must return zero if a === b, negative if a < b, positive if a > b.
     *
     *   aId and bId are the DataTables ideas of what the IDs are for the
     *   objects represented by a and b. These may be used to override the
     *   value.
     *
     *   The given compareFn is called to compare values when sorting on a custom
     *   column. If not provided, the implementation will attempt to do either
     *   a string or numeric comparison, otherwise an exception will be thrown.
     */
    customCells : PropTypes.object,

    /**
     * insertCells is a callback of the form (id, rowTypes.rowType, rowData) => {}
     * if provided, it is called once per row. The user must return an array
     * containing one or more table cell (td) contents as JSX fragments.
     *
     * Must have signature (key, rowType, rowData) => []
     */
    insertCells : PropTypes.func,

    /**
     * insertCellsAfter is a callback of the form (id, rowTypes.rowType, rowData) => {}
     * if provided, it is called once per row. The user must return an array
     * containing one or more table cell (td) contents as JSX fragments.
     *
     * Must have signature (key, rowType, rowData) => []
     */
    insertCellsAfter : PropTypes.func,

    /**
     * Enables the selection column. onSelect is called every time a change is made.
     */
    onSelect: PropTypes.func,

    noSelect: PropTypes.instanceOf(Set).isRequired,

    /**
     * An array of custom filter functions that can be applied to each row.
     * Each function must have the signature:
     * (Object rowData) => Boolean
     *
     * IMPORTANT: Custom filters cannot be arrow functions defined within the
     * users render function. The DataTable implementation must be able to
     * compare the previous custom filter prop against the previous value
     * to determine if the prop has changed and the filter must be evaluated
     * again.
     *
     * Where rowData is an object with properties containing individual data
     *
     * fields for the row, and a return value of true indicates the row
     * passed the filter test and should be displayed.
     *
     * A row must pass all filters (all return true) to be included.
     */
    customFilter : PropTypes.arrayOf(PropTypes.func),

    /**
     * If set, will be called with the data for the table once it is loaded.
     * (data) => undefined
     */
    onDataLoad: PropTypes.func,

    /**
     * If true, the table will be rendered with borders and striping.
     * Otherwise the table will be more plain. Default is true.
     */
    decorate: PropTypes.bool,

    /**
     * Add borders if decorate is false
     */
    borders: PropTypes.bool,

    /**
     * Height in pixels of the header row
     */
    headerRowHeight: PropTypes.number,
};

DataTable.defaultProps = {
    uniqueIdField: 'id',
    noDataMessage: 'No rows',
    noDataFilterMessage: 'No rows match',
    decorate: true,
    borders: true,
    noSelect: new Set(),
}

export { DataTable };
