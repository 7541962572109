function expandCamelCase(str) {
    return str.replace(
        /(?:^[a-z])|(?:\w[A-Z])/gu,
        (match, offset) => {
            if (offset === 0) return match.toUpperCase();

            return `${match[0]} ${match[1]}`;
        }
    );

}

module.exports = { expandCamelCase };
