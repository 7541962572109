/* eslint-disable */

const assert = require('assert');

import { makeObservable, computed, observable, flow, action, runInAction, toJS } from "mobx";

class AuthStore {
    constructor(api) {
        assert(api, 'api must be first argument');

        this._api = api.axios;

        this._user = null;
        this._timeoutWarn = false;

        makeObservable(
            this,
            {
                _user: observable,
                _timeoutWarn: observable,

                setTimeoutWarn: action,
                resetTimeoutWarn: action,

                _clearUser: action,

                logoutAsync: action,
                clearAuthentication: action,
                authenticateUser: action,
            }
        );
    }

    kill() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = null;
        }
    }

    get user() { return this._user };

    get timeoutWarn() {
        return this._timeoutWarn;
    }

    noAuthReason() {
        return 'None';
    }

    _clearUser() {
        this._user = null;
    }

    setTimeoutWarn(warnTime) {
        console.log('timeoutWarn now true');
        this._timeoutWarn = true;

        if (this.timeoutHandle) clearTimeout(this.timeoutHandle);

        // If user does not intervene - clear the user once the login expires.
        // This will trigger PrivateRoute to redirect to login as it observes
        // the authStore.
        this.timeoutHandle = setTimeout(
            () => this._clearUser(),
            warnTime
        );
    }

    resetTimeoutWarn() {
        this._timeoutWarn = false;

        const timeToExpiry = this._user.authExpiryTime - Date.now();
        console.log('Auth expires in: ', Math.floor(timeToExpiry / 1000), 'sec');

        // Five minutes or 25% of the session expiry time, whichver is shorter.
        const warnTime = Math.min(Math.floor(timeToExpiry * 0.25), 5 * 60 * 1000);
        console.log('warnTime is ', warnTime);

        if (this.timeoutHandle) clearTimeout(this.timeoutHandle);

        this.timeoutHandle = setTimeout(
            () => this.setTimeoutWarn(warnTime),
            timeToExpiry - warnTime
        );
    }

    async logoutAsync() {
        console.trace();
        try {
            await this._api.delete('/login');
            console.log('[Auth.logoutAsync] Logged out');

        } catch (err) {
            console.error('[Auth.logoutAsync] Logout error');
            console.error(err);
        }

        runInAction(() => this.clearAuthentication());
    }

    clearAuthentication() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = null;
            this._timeoutWarn = false;
        }

        this._user = null;
    }

    async authenticateUser(username, pass) {
        console.log('authenticating user');

        if (this._user === null) {
            try {
                const response = await this._api.post('/login', { user: username, pass });

                console.log('POST:', response);
                console.log('creating auth');

                // Note, Firefox may print a console warning that the cookie has been
                // rejected because it is already expired. This is in response to the
                // existing cookie being cleared using a null or empty string and
                // can be ignored.
                // https://bugzilla.mozilla.org/show_bug.cgi?id=1676651

                const user = {
                    name: response.data.email,
                    user: response.data.email,
                    permissions: response.data.permissions,
                    authExpiryTime: response.data.expiresAt * 1000
                };

                runInAction(() => {
                    this._user = user; 
                    this.resetTimeoutWarn();
                });

                return user;

            } catch (error) {
                console.log('POST:', error);
                console.log('response', error.response);

                if (error.response && error.response.data) {
                    throw new Error(error.response.data.message);
                }

                throw new Error(`Gateway appears to be down.`);
            }
        }

        return this._user;
    }

    async refreshUserAsync() {
        console.log('refreshUserAsync');
        console.trace();

        if (this._auth !== null) {
            console.log('[currentAuthenticatedUserAsync] Refreshing token');

            // Refresh the token
            try {
                const response = await this._api.get('/auth/refresh');

                runInAction(() => {
                    this._user = {
                        name: response.data.email,
                        user: response.data.email,
                        permissions: response.data.permissions,
                        authExpiryTime: response.data.expiresAt * 1000
                    };

                    this.resetTimeoutWarn();
                });

            } catch (error) {
                console.log(`[currentAuthenticatedUserAsync] Got error. ${error?.response?.data?.message}`);
                //reject(new Error(error.response.data.message));
                runInAction(() => this.clearAuthentication());

                //if (error.response && error.response.data) throw(new Error(error.response.data.message));
                //throw(error);
            }

        } else {
            console.log('[currentAuthenticatedUserAsync] Checking existing token');

            try {

                const response = await this._api.get('/login');

                console.log('[Auth.currentAuthenticatedUserAsync] response:', response.data);

                if (response.data.success) {
                    runInAction(() => {
                        this._user = {
                            name: response.data.email,
                            user: response.data.email,
                            userLevel: response.data.userLevel,
                            authExpiryTime: response.data.expiresAt * 1000
                        };

                        this.resetTimeoutWarn();
                    });
                    //resolve(_auth.user);

                } else {
                    //reject(new Error(response.data.message || 'Unauthorised'));
                    runInAction(() => this.clearAuthentication());
                }

            } catch (error) {
                console.log(error?.response?.data?.message);
                //if (error.response && error.response.data) throw(new Error(error.response.data.message));
                //else throw(error);
            }
        }
    }
}

export { AuthStore };
