/**
 * @module utils
 */


async function sleepAsync(ms) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

let lastMicroTime = 0;
function microtime() {
    // Returns in a format that _could_ support microseconds, but only
    // millisecond accuracy is currently provided.

    const now = Date.now();

    // Add some variation to the micro seconds
    return [now, lastMicroTime++ % 1000];
}

module.exports = { sleepAsync, microtime };
