import React from 'react';

import PropTypes from 'prop-types';

import Form     from 'react-bootstrap/Form';

import { HoverForHelp } from '../general/HoverForHelp';

import { nullable } from '../utils/propTypes';

/**
 * Checkbox that supports true, false, and indeterminate.
 * This is a controlled input.
 */
class BooleanInput extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
        if (this.props.onChange) {
            if (this.props.domStyleEvtArgs) {
                this.props.onChange({ target: {id: evt.target.id, value: evt.target.checked }});

            } else {
                this.props.onChange(evt.target.id, evt.target.checked);
            }
        }
    }

    _setIndeterminate(indeterminate) {
        const node = this.ref.current;
        node.indeterminate = indeterminate;
    }

    componentDidMount() {
        if (this.props.value === null && this.props.indeterminateOnNull === true) {
            this._setIndeterminate(true);
        }
    }

    componentDidUpdate() {
        if (this.props.value === null && this.props.indeterminateOnNull === true) {
            this._setIndeterminate(true);
        } else {
            this._setIndeterminate(false);
        }
    }

    render() {
        let label = this.props.help
                    ? <span>
                        {this.props.label}
                        <span className='ms-1'>
                            <HoverForHelp>{this.props.help}</HoverForHelp>
                        </span>
                    </span>
                    : this.props.label;

        if (this.props.value === null && this.props.messageOnNull) {
            label = <>{label} <span className='text-secondary'>{this.props.messageOnNull}</span></>;
        }

        return (
            <>
                <Form.Check
                    type      = 'checkbox'
                    id        = { this.props.id }
                    onChange  = { this.handleChange }
                    checked   = { this.props.value || false }
                    disabled  = { this.props.disabled || null }
                    label     = { label }
                    isValid   = { this.props.isValid || null }
                    isInvalid = { this.props.isInvalid ||  null }
                    feedback  = { this.props.feedback || null }
                    ref       = { this.ref }
                    className = { `pt-2 pb-2 ${this.props.className}` }
                />
            </>
        );
    }
}

BooleanInput.defaultProps = {
    label: '',
    domStyleEvtArgs: false,
};

BooleanInput.propTypes = {
    id        : PropTypes.string.isRequired,
    label     : PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    help      : PropTypes.string,
    value     : nullable(PropTypes.bool).isRequired,
    onChange  : PropTypes.func,
    disabled  : PropTypes.bool,
    className : PropTypes.string,
    isValid   : PropTypes.bool,
    isInvalid : PropTypes.bool,
    feedback  : PropTypes.string,
    indeterminateOnNull : PropTypes.bool,

    /**
     * If set, this message will be appended to the label when the value is null
     */
    messageOnNull: PropTypes.string,

    /**
     * If onChange is called with a single evt argument (similar to standard
     * DOM event callbacks) or with our standard (id, value) arguments.
     */
    domStyleEvtArgs: PropTypes.bool.isRequired,
};

export { BooleanInput };
