/**
 * @module utils
 */

const { binarySearch } = require('./array');

class PriorityQueue {
    /**
     * @param {Function} [compareFunction]  Specifies a function that defines
     *                                      the sort order. By default, any
     *                                      type that supports comparison using
     *                                      the `<` operator is supported and
     *                                      priority is ordered with lowest value
     *                                      first.
     */
    constructor(compareFunction = (a,b) => b - a) {
        this.data = [0]; // 0 is to fill out positon 0. It is not used.
        this.cmpFn = compareFunction;
    }

    _bubbleUp() {
        let i = this.data.length - 1;
        const d = this.data;

        while (i) {
            const parent = i >> 1; //Math.floor(i/2);
            if (this.cmpFn(d[i], d[parent]) > 0) {
                // parent is greater. swap.
                [d[i],d[parent]] = [d[parent],d[i]];
                i = parent;

            } else {
                // Should be able to bail if nothing is done at the first step.
                return;
            }
        }
    }

    _minChild(i) {
        const left = i << 1; //i * 2;
        const right = left + 1; //i * 2 + 1;

        if (left >= this.data.length) return null;
        if(right >= this.data.length) return left;

        if (this.cmpFn(this.data[left], this.data[right]) < 0) return right;

        return left;
    }

    _bubbleDown(startAt = 1) {
        let i = startAt;
        const d = this.data;

        while ((i << 1) < d.length) {
            const mc = this._minChild(i);
            if (mc === null) return;

            if (this.cmpFn(d[i], d[mc]) < 0) {
                // mc < i
                [d[i], d[mc]] = [d[mc], d[i]];
                i = mc;

            } else {
                return;
            }
        }

    }

    insert(o) {
        this.data.push(o);
        this._bubbleUp();
    }

    // Returns true if the removed object is next in priority order
    remove(o) {
        // Two elements in the array satisfy the heap property, but not the
        // entire array as a whole. So we can't short circuit the test.
        // E.g. previously used binary search to determine i which is wrong.
        let i = 1;

        // We have been returned the lowest index that satisfies the test.
        // But this might not be the object looked for, just one with the
        // same test value. Look at following indexes to find the exact match.
        while (this.data[i] !== o && i < this.data.length) {
            i++;
        }

        if (i >= this.data.length) return false;

        this.data[i] = this.data[this.data.length - 1];
        this.data.pop();
        this._bubbleDown(i);

        return i === 1;
    }

    // find_min
    peek() {
        return this.data[1];
    }

    // del_min
    pop() {
        const r = this.data[1];

        this.data[1] = this.data[this.data.length - 1];
        this.data.pop(); // Remove duplicated last element
        this._bubbleDown();

        return r;
    }

    size() {
        return this.data.length - 1;
    }

    clear() {
        this.data = [0];
    }
}

module.exports = { PriorityQueue };
