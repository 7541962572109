import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import Creatable   from 'react-select/creatable';

import { requireWith } from '../utils/propTypes';

class NullableSelect extends React.Component {
    constructor(props) {
        super(props);
        this.hasChanged = false;

        this.newOptionBeingTyped = '';

        this.handleChange = this.handleChange.bind(this);
        this._invalidNewOptionMessage = this._invalidNewOptionMessage.bind(this);
        this._isValidNewOption = this._isValidNewOption.bind(this);
    }

    handleChange(evt) {
        this.hasChanged = true;
        this.props.onChange(evt);
    }

    _invalidNewOptionMessage() {
        if (this.newOptionBeingTyped.length === 0 && this.props.options.length === 0) {
            return 'No options';
        }

        return this.props.invalidNewOptionMessage || 'invalid';
    }

    _isValidNewOption(inputValue, selectValue, selectOptions) {
        this.newOptionBeingTyped = inputValue;

        return this.props.isValidNewOption(inputValue, selectValue, selectOptions);
    }

    render() {
        const style = {}, addStyles = {...this.props.styles};
        const placeholder = this.props.placeholder || undefined;

        let Component = Select;

        if (this.props.allowCreate === true) Component = Creatable;

        // Merge sub-object sfrom this.props.style and addStyles and wrap
        // in an arrow function for react-select
        for (const styleKey of Object.keys(addStyles)) {
            style[styleKey] = styles => ({
                ...styles,
                ...addStyles[styleKey]
            });
        }

        const components = this.props.components || {};

        return (
            <Component
                options={this.props.options}
                isClearable={this.props.isClearable || false}
                onChange={this.handleChange}
                placeholder = {placeholder}
                styles = { style }
                value = { this.props.value || null} // null must be used for no selection

                isValidNewOption = {this.props.allowCreate ? this._isValidNewOption : undefined}
                noOptionsMessage = {this.props.allowCreate ? this._invalidNewOptionMessage : undefined}

                isSearchable    = { this.props.isSearchable }
                components      = { components }

                isDisabled      = { this.props.isDisabled }
            />

        );
    }
}

NullableSelect.propTypes = {
    options : PropTypes.array.isRequired,

    /**
     * An object with properties 'value' and 'label' as required by react-select
     */
    value   : PropTypes.object,

    /**
     * Placeholder text displayed if nothing is selected.
     */
    placeholder : PropTypes.string,

    /**
     * User on change handler.
     * See react-select documentation for explanation of the detail parameter.
     * (Number id, Array values, detail) => undefined
     */
    onChange: PropTypes.func,

    /**
     * Allow creation of new options
     */
    allowCreate : PropTypes.bool,

    /**
     * Validation function for new options, with signature:
     * function(string) => boolean
     * Ignored if allowCreate=false.
     */
    isValidNewOption : PropTypes.func,

    /**
     * Message to display if isValidNewOption returns false
     */
    invalidNewOptionMessage: PropTypes.string,

    /**
     * Component HTML id attribute
     */
    id: requireWith('onChange', 'string'),

    /**
     * See docs for react-select
     */
    isSearchable : PropTypes.bool,

    /**
     * See docs for react-select
     */
    components : PropTypes.object,

    /**
     * An object containing react-select style objects. react-select utilises
     * different style objects for each part of the component. Each of these
     * will be a sub object.
     * Note the style objects accepted here are pure objects and not arrow
     * functions as in react-select.
     */
    styles: PropTypes.object,

    /**
     * If true, the component value is immutable and the select menu is not available
     */
    isDisabled: PropTypes.bool,

    isClearable: PropTypes.bool,
};

export { NullableSelect };
