import React from 'react';

import rowTypes from './rowTypes';

function insertCellsToTds(insertFn, key, rowType, rowData = null, trRef = null) {
    if (!insertFn) return null;

    const insertTd = insertFn(key, rowType, rowData, trRef);

    if (!insertTd) return null;

    const align = rowType === rowTypes.header ? 'baseline' : 'middle';

    return insertTd.map(
        (cell, i) => {
            return (
                <td key={`${key}_${i}`} className='pt-0 pb-0' style={{verticalAlign: align, width: '2rem'}}>
                    <div style={{display: 'flex', alignContent: 'baseline', whiteSpace: 'nowrap'}}>
                        {cell}
                    </div>
                </td>
            );
        }
    );
}

export {
    insertCellsToTds,
};
