function whenSpecObjFromArray(whenSpecArray) {
    if (whenSpecArray.length === 0) {
        return [];
    }

    const whenSpec = [];

    const storeRun = (start, end, step) => {
        const obj = {
            min: start,
            max: end
        }

        if (step && step > 1 && start !== end) obj.step = step;

        whenSpec.push(obj);
    }

    let runStart = whenSpecArray[0];

    let i = 1;
    let vNext = undefined;

    do {
        const vPrev = whenSpecArray[i - 1];
        const v = whenSpecArray[i];
        vNext = whenSpecArray[i + 1];

        const distPrev = v - vPrev;
        const distNext = vNext ? vNext - v : null;

        if (!vNext || distPrev < distNext) {
            // End of run at current value. If v is undefined we are in a
            // single element array and need to use the previous value.
            storeRun(runStart, v || vPrev, distPrev);

            // set next as start of new run and move loop past next
            runStart = vNext;

        } else if (distPrev > distNext && runStart <= vPrev) {
            // End run, not including current value
            storeRun(runStart, vPrev, distPrev);

            // set current as start of new run
            runStart = v;
        }

        i++;
    } while (vNext !== undefined);

    return whenSpec;
}

module.exports = { whenSpecObjFromArray };
