import React from 'react';
import PropTypes from 'prop-types';

import Ajv from 'ajv';
import addFormats from "ajv-formats"

function _buildErrorList(validationErrors) {
    const errorList = {};

    for (const err of validationErrors) {
        let id = null;

        if (err.params && err.params.missingProperty) {
            id = err.params.missingProperty;

        } else {
            const idParts = err.instancePath.split('/');
            // dataPath uses / notation to reference properties within an object
            // structure - e.g. /broker/port
            // Currently we are always associating with the top level id since it's
            // assumed the error should be associated with the top level control
            // in a form (and the child object is rendered entirely by another component).
            // i.e. The error is shown in at the top level of a ValidatedSchemaForm
            // and the child object is included as a custom component.
            id = idParts[1];
        }

        if (errorList[id]) errorList[id].push(err.message);
        else errorList[id] = [err.message];
    }

    return errorList;
}

const Validated = props => {
    // Pass through all props except schema, which isn't required in the child,
    // and onChange, which we over-ride.
    // eslint-disable-next-line no-unused-vars
    const { schema, onChange, labelFromSchema, coerceTypes, customValidation, onLoad, ...passThroughProps } = props;

    const required = schema.required && (schema.required.indexOf(props.id) > -1);

    const validateFn = React.useMemo(() => {
        const ajv = new Ajv({
            allErrors: true,
            coerceTypes: coerceTypes || false,
            strict: 'log'
        });

        addFormats(ajv);
        ajv.addKeyword({ keyword: 'errorMessage' });

        return ajv.compile(schema);

    }, [schema, coerceTypes]);

    const validatePartial = value => {
        if (validateFn(value)) return null;

        const errList = _buildErrorList(validateFn.errors);

        for (const errField of Object.keys(errList)) {
            if (!(errField in value)) {
                delete errList[errField];
            }
        }

        return Object.keys(errList).length > 0 ? errList : null;
    };

    const validate = value => {
        const errors = validatePartial({ [props.id]: value });

        const customErrs
            = customValidation
            ? (customValidation(props.id, value) || {})
            : {};

        let valid = true;
        let messages = [];

        if (errors !== null) {
            messages
                = schema.properties && schema.properties[props.id].errorMessage
                ? [schema.properties[props.id].errorMessage]
                : errors[props.id];

            valid = false;
        }

        if (customErrs[props.id]) {
            messages.push(...customErrs[props.id]);
            valid = false;
        }

        return { valid, errors: messages };
    };

    const [changed, setChanged] = React.useState(() => !props.noMarkupOnLoad);

    const [validation, setValidation] = React.useState(() => {
        const valid = validate(props.value);
        if (onLoad) onLoad(props.id, valid);

        return valid;
    });

    const handleChange = (id, value) => {
        const validation = validate(value);
        onChange(id, value, !validation.valid);
        setValidation(validation);
        setChanged(true);
    }

    const validProps
        = !changed || (!props.markupOnBlank && (props.value === undefined || props.value === ''))
        ? {}
        : {
            isValid: validation.valid && !props.forceInvalid,
            isInvalid: validation.valid === false || props.forceInvalid, // null/undefined is NOT invalid
        };

    return props.render({
        label: labelFromSchema
            ? schema.properties[props.id].title
            : undefined,
        help: labelFromSchema && schema.properties[props.id].description
            ? schema.properties[props.id].description
            : undefined,
        onChange: handleChange,
        ...validProps,
        isRequired: required,
        errors: changed && validation.valid === false ? validation.errors : [],
        ...passThroughProps
    });
}

Validated.propTypes = {
    id: PropTypes.string.isRequired,
    schema: PropTypes.object.isRequired,
    labelFromSchema: PropTypes.bool,
    value: PropTypes.any,

    /**
     * User function called when the data is changed.
     * Signature: (id, value, invalid) => {}
     */
    onChange: PropTypes.func.isRequired,

    onLoad: PropTypes.func,

    /**
     * Perform additional custom validation on the data as it is changed.
     * Called once per input change, passing the entire values object.
     * Returns an object containing set properties for any invalid fields,
     * the properties are set to an array of error messages.
     *
     * Signature: function(String id, Object values) => Object
     */
    customValidation: PropTypes.func,


    render: PropTypes.func.isRequired,

    coerceTypes: PropTypes.bool.isRequired,

    /**
     * Whether to show valid/invalid markup on a blank value
     */
    markupOnBlank: PropTypes.bool.isRequired,

    forceInvalid: PropTypes.bool,
};

Validated.defaultProps = {
    coerceTypes: false,
    markupOnBlank: true,
}

export { Validated };

