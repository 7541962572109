/* eslint-disable no-unused-vars */

import React from 'react';

const UISettings = React.createContext({
    orderDate: null,
    setOrderDate: orderDate => {},

    errors: [],
    pushError: e => {},
    clearError: e => {},
});

export { UISettings };
