import React from 'react';
import PropTypes from 'prop-types';

import kiwi from '../../assets/LoginIcon.png';
import logo from '../../assets/LocalLunches.png';

function HeaderShort(props) {
    const { render, ...passThroughProps } = props;

    return (
        <>
            <header className='headerShort' >
                <img id='logo_image' src={logo} height='69px' alt='Company logo'/>
                <img src={kiwi} alt='Company logo' height='69px'/>
            </header>
            {render({ ...passThroughProps })}
        </>
    );
}

HeaderShort.propTypes = {

    /**
     * Render prop for rendering the child component with the injected value.
     */
    render: PropTypes.func.isRequired,
};

export { HeaderShort };
