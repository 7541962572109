import { makeObservable, observable, flow } from 'mobx';

class UsersStore {
    constructor(api) {
        this._users = [];
        this._assignableRoles = [];
        this._defaultRoles = [];

        this.loading = true;

        this._api = api;

        makeObservable(
            this,
            {
                loading: observable,
                _users: observable,
                _assignableRoles: observable,
                _defaultRoles: observable,
                loadAssignableRoles: flow,
                load: flow,

                deleteUsers: flow,
                createUser: flow,
                updateUser: flow,
            }
        );
    }

    *load() {
        const res = yield this._api.axios.get('/users');

        try {
            // May fail due to permissions
            yield this.loadAssignableRoles();

        } catch (err) {
            console.log(err);
        }

        this._users = res.data.users.map(e => ({ ...e, numRoles: e.roles.length }));
        this.loading = false;
    }

    *loadAssignableRoles() {
        const res = yield this._api.axios.get('/users/self/roles');
        this._assignableRoles = res.data.assignableRoles;
        this._defaultRoles = res.data.defaultRoles;
    }

    get users() {
        return this._users;
    }

    getUser(userId) {
        return this._users.find(e => e.userId === userId);
    }

    get assignableRoles() {
        return this._assignableRoles;
    }

    get defaultRoles() {
        return this._defaultRoles;
    }

    *deleteUsers(userIdSet) {
        const res = yield this._api.axios.delete(
            '/users',
            { params: { emails: this._users.filter(e => userIdSet.has(e.userId)).map(e => e.email) }}
        );

        const deletedSet = new Set(res.data.deleted);

        this._users = this._users.filter(e => !deletedSet.has(e.email));
    }

    *createUser(user) {
        const res = yield this._api.axios.post('/users', user);

        this._users.push({ numRoles: res.data.user.roles.length, ...res.data.user });
    }

    *updateUser(userId, user) {
        const res = yield this._api.axios.patch('/users', { userId, ...user });
        const updatedUser = res.data.user;

        this._users = this._users.map(
            e => (e.userId === userId ? {...updatedUser, numRoles: updatedUser.roles.length } : e)
        );
    }
}

export { UsersStore };
