module.exports
    = process.env.REACT_APP_VERSION
    ? {
        array:          require('./array'),
        concurrency:    require('./concurrency'),
        ctf:            require('./ctf'),
        Mutex:          require('./Mutex').Mutex,
        PriorityQueue:  require('./PriorityQueue').PriorityQueue,
        set:            require('./set'),
        timing:         require('./timing'),
        ExtMap:         require('./ExtMap').ExtMap,
        ExtEmitter:     require('./ExtEmitter').ExtEmitter,
        interpolator:   { ...require('./KeyframeInterpolator') },
        num:            require('./num'),
        string:         require('./string'),
        obj:            require('./obj'),
        whenSpec:       require('./whenSpec'),
    }
    : {
        argv:           require('./argv'),
        array:          require('./array'),
        buffer:         require('./buffer'),
        concurrency:    require('./concurrency'),
        ctf:            require('./ctf'),
        db:             require('./db'),
        env:            require('./env'),
        errors:         require('./errors'),
        express:        require('./express'),
        FetchCache:     require('./FetchCache').FetchCache,
        Cache:          require('./Cache').Cache,
        files:          require('./files'),
        version:        require('./version'),
        inet:           require('./inet'),
        logging:        require('./logging'),
        Mutex:          require('./Mutex').Mutex,
        PriorityQueue:  require('./PriorityQueue').PriorityQueue,
        set:            require('./set'),
        subprocess:     require('./subprocess'),
        TaskQueue:      require('./TaskQueue').TaskQueue,
        timing:         require('./timing'),
        ExtMap:         require('./ExtMap').ExtMap,
        ExtEmitter:     require('./ExtEmitter').ExtEmitter,
        interpolator:   { ...require('./KeyframeInterpolator') },
        num:            require('./num'),
        uuid:           require('./uuid'),
        string:         require('./string'),
        obj:            require('./obj'),
        whenSpec:       require('./whenSpec'),
        password:       require('./password'),
    };
