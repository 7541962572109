import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Alert   from 'react-bootstrap/Alert';

import { withAuth } from './hoc/withAuth';

import { Login } from './Login';
import { ErrorSetter } from './Error';

import { SubmitButton } from './general/SubmitButton';
import { StatusModel } from '../model/StatusModel.js';

class Landing extends ErrorSetter {
    constructor(props) {
        super(props);

        this.model = new StatusModel(this.props.api);
        this.state = {
            loading: true,
            initialised: false
        };

        this.handleReload = this.handleReload.bind(this);
    }

    async componentDidMount() {
        let data = null;

        try {
            data = await this.model.get();

            if (data.initialised) {
                try {
                    await this.props.authStore.logoutAsync();

                } catch { } // eslint-disable-line no-empty
            }

            this.setState({
                loading: false,
                initialised: data.initialised
            });

        } catch (error) {
            // For axios, errors are either HTTP errors with error.status set,
            // or general "Network errors", which mean the server is not contactable.
            if (error.status) {
                this.setErrorState(error);
            } else {
                this.setState({
                    loading: false,
                    initialised: false
                });
            }
        }
    }

    async handleReload() {

        return new Promise( resolve => {
            setTimeout(
                () => {
                    window.location.reload(false);
                    resolve(true);
                    //setTimeout( () => this.props.history.push('/login'), 500);
                },
                2000
            );
        });
    }

    render() {
        if (this.state.fatalError) {
            return(
                <Redirect to={{pathname: '/fatalError',}} />
            );
        }

        if (this.state.initialised) {
            return <Login onLoginSuccess={this.props.onLoginSuccess}/>;
        }

        if (this.state.loading) {
            return (
                <div className='LandingLoading text-center'>
                    <p>Loading</p>
                    <Spinner animation='border' variant='info' />
                </div>
            );
        }

        return (
            <div className='Landing'>
                <Alert variant='secondary' className='centered-box mt-3'>
                    <Alert.Heading>Server down</Alert.Heading>
                    <p>
                    Down for maintenance.
                    </p>
                    <div className='d-flex justify-content-end'>
                        <SubmitButton
                            onSubmit={this.handleReload}
                            className='ms-2'
                            confirmSubmit={false}
                            stateMessages={true}
                            successMessage='Reloading'
                            pendingMessage='Please wait...'
                            messageTime={1000}
                        >
                        Refresh
                        </SubmitButton>
                    </div>
                </Alert>
            </div>
        );
    }
}

Landing.propTypes = {
    api: PropTypes.object.isRequired,

    /**
     * Passed through to Login component - see docs there.
     */
    onLoginSuccess: PropTypes.func,
};

const LandingWithAuth = withAuth(Landing);

export { LandingWithAuth as Landing };
