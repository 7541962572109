import React from 'react';
import PropTypes from 'prop-types';

import Form     from 'react-bootstrap/Form';
import Button   from 'react-bootstrap/Button';

import { ReactComponent as EyeSVG } from '../../assets/visibility_black_24dp.svg';
import { ReactComponent as EyeOffSVG } from '../../assets/visibility_off_black_24dp.svg';

import { requireWith } from '../utils/propTypes';

class Password extends React.Component {
    constructor(props) {
        super(props);

        this.state = { hidden: this.props.startHidden }

        this.handleHideToggle = this.handleHideToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleHideToggle() {
        this.setState(prevState => ({ hidden: !prevState.hidden }));
    }

    handleChange(evt) {
        const { value } = evt.target;
        this.props.onChange(this.props.id, value);
    }

    render() {
        const strValue = this.props.value || '';

        const EyeIcon = this.state.hidden ? EyeSVG : EyeOffSVG

        return (
            <div style={{ display: 'flex', width: '100%' }}>
                <Form.Control
                    autoComplete = { this.props.autoComplete || null }
                    type      = { this.state.hidden ? 'password' : 'text' }
                    onChange  = { this.handleChange }
                    onBlur    = { this.props.onBlur }
                    value     = { strValue }
                    isValid   = { this.props.isValid || null }
                    isInvalid = { this.props.isInvalid ||  null }
                    style     = { this.props.style }
                />
                <Button size='sm' variant='outline-grey' onClick={this.handleHideToggle} >
                    <EyeIcon style={{height: '1.0rem'}}/>
                </Button>
            </div>
        );
    }
}

Password.propTypes = {

    /**
     * An identifier provided as the first argument to the onChange function.
     */
    id: requireWith('onChange', 'string'),

    /**
     * The currently set passed - empty string is ok.
     */
    value     : PropTypes.string,

    /**
     * Callback executed when the value is changed.
     * Must have the signature (String id, String value) => undefined
     */
    onChange  : PropTypes.func,

    /**
     * Callback executed when the element loses focus - see bootstrap docs.
     */
    onBlur    : PropTypes.func,

    /**
     * True to indicate if element should be marked as validated successfully
     */
    isValid   : PropTypes.bool,


    /**
     * True to indicate if element should be marked as validated unsuccessfully
     */
    isInvalid : PropTypes.bool,

    /**
     * Whether the password is initially hidden or not.
     */
    startHidden : PropTypes.bool.isRequired,

    /**
     * Set the autoComplete hints. This prop shadows the HTML attribute.
     * Hint: use 'new-password' for any forms where password should never
     * be autocompleted.
     */
    autoComplete: PropTypes.string,


    /**
     * Add additional styles to base Control
     */
    style: PropTypes.object,
};

Password.defaultProps = {
    startHidden: true
}

export { Password };
