import React from 'react';

import PropTypes from 'prop-types';

import Form     from 'react-bootstrap/Form';

import { nullable } from '../utils/propTypes';

class NumberInput extends React.Component {
    constructor(props) {
        super(props);

        // Previously, the value wasn't being copied into the state (yes I know)
        // it's generally frowned upon to do so. If any regressions pop up with
        // this component it may be caused by this change.
        this.state = {
            value: props.value,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFromNull = this.handleChangeFromNull.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    handleChange(evt) {
        const { value } = evt.target;

        // isNaN('') returns false but parseInt('',10) returns NaN *facepalm*
        if (value === '-' || value === '' || isNaN(value)) {
            this.props.onChange(this.props.id, value);

        } else if (value.startsWith('0x')) {
            this.props.onChange(this.props.id, parseInt(value, 16));

        } else {
            this.props.onChange(this.props.id, parseFloat(value));
        }

        this.setState({ value })
    }

    handleChangeFromNull() {
        this.props.onChange(this.props.id, '');
    }

    render() {
        let className = null;

        if (this.props.value === null && this.props.shadeOnNull === true) {
            className = 'input-shaded';
        }

        // Don't convert to a number of any type as the user may have only
        // entered a negative symbol so far.
        let strValue = this.state.value;
        let onClickHandler = null;

        if (this.props.value === null && this.props.messageOnNull) {
            strValue = this.props.messageOnNull
            onClickHandler = this.handleChangeFromNull;
        }

        return (
            <Form.Control
                className = { className }
                as        = 'input'
                onClick   = { onClickHandler }
                onChange  = { this.handleChange }
                onBlur    = { this.props.onBlur }
                value     = { strValue }
                isValid   = { this.props.isValid || null }
                isInvalid = { this.props.isInvalid ||  null }
                disabled  = { this.props.disabled || false }
                style     = { this.props.style }
            />
        );
    }
}

NumberInput.propTypes = {
    id        : PropTypes.string.isRequired,
    onChange  : PropTypes.func,
    onBlur    : PropTypes.func,
    isValid   : PropTypes.bool,
    isInvalid : PropTypes.bool,

    /**
     * The numeric value displayed by the component, or null
     */
    value : nullable(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

    /**
     * Will display this message string if the value is null. In addition, when
     * the input is clicked, the value will become the empty string '' and
     * the message string will be cleared.
     */
    messageOnNull: PropTypes.string,

    /**
     * Will visually shade the input if the value is null
     */
    shadeOnNull : PropTypes.bool,

    /**
     * Whether the element is disabled and does not allow modification
     */
    disabled: PropTypes.bool,

    style: PropTypes.object.isRequired,
};

NumberInput.defaultProps = {
    id: 'NumberInput',
    style: {},
};

class RestrictedNumberInput extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = { value: props.value, valid: !isNaN(props.value) };
    }

    handleChange(evt) {
        const { value } = evt.target;

        const intVal = parseInt(value, 10);

        // While restricted to numbers, invalid output must still be possible
        // e.g. a minus sign, or an empty value must be possible.
        // We could validate in here, or externally.
        if (!isNaN(intVal)) {
            const invalid = intVal < this.props.minValue || intVal > this.props.maxValue;
            this.props.onChange(this.props.id, intVal, invalid);
            this.setState({ value: intVal, valid: !invalid });

        } else if (value === '-' || value === '') {
            this.props.onChange(this.props.id, intVal, true);

            this.setState({ value, valid: false });
        }
    }

    render() {
        const valid
            = this.state.valid
            && (typeof this.props.isValid === 'boolean' ? this.props.isValid : true);

        return (
            <Form.Control
                as        = 'input'
                onChange  = { this.handleChange }
                onBlur    = { this.props.onBlur }
                value     = { this.state.value }
                isValid   = { valid }
                isInvalid = { !valid }
                disabled  = { this.props.disabled || false }
            />
        );
    }
}

RestrictedNumberInput.propTypes = {
    id        : PropTypes.string.isRequired,
    onChange  : PropTypes.func,
    onBlur    : PropTypes.func,
    isValid   : PropTypes.bool,
    isInvalid : PropTypes.bool,

    minValue : PropTypes.number.isRequired,
    maxValue : PropTypes.number.isRequired,

    /**
     * The numeric value displayed by the component, or null
     */
    value : nullable(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

    /**
     * Will display this message string if the value is null. In addition, when
     * the input is clicked, the value will become the empty string '' and
     * the message string will be cleared.
     */
    messageOnNull: PropTypes.string,

    /**
     * Will visually shade the input if the value is null
     */
    shadeOnNull : PropTypes.bool,

    /**
     * Whether the element is disabled and does not allow modification
     */
    disabled: PropTypes.bool,
};

RestrictedNumberInput.defaultProps = {
    id: 'NumberInput',
    minValue: -Infinity,
    maxValue: Infinity,
};

export { NumberInput, RestrictedNumberInput };
