const { binarySearchClosest } = require('./array');

const lerp = (v0, v1, t) => (1 - t) * v0 + t * v1;

class KeyframeInterpolator {
    constructor(keyframes, tMax = 1) {
        this.range = [0, tMax];
        this.rangeDistance = tMax;

        if (keyframes.length < 1) throw new Error('At least one keyframe must be provided');

        if (keyframes.some(e => e.t < this.range[0] || e.t >= this.range[1])) {
            throw new Error('Keyframes outside of range');
        }

        // Scale all keyframes into the range [0-1], sort, and remove (first of) duplicated times
        this.keyframes
            = keyframes.map(e => ({ t: this._scaleT(e.t), v: e.v }))
            .sort((a, b) => a.t - b.t)
            .filter((e, i, a) => a[i + 1] ? e.t !== a[i + 1].t : true);
    }

    // Scale a t value, given relative tMax, into the range [0-1]
    _scaleT(t) {
        return Math.abs(((t - this.range[0]) / this.rangeDistance) % 1);
    }

    _unscaleT(t) {
        return t * this.rangeDistance;
    }

    nextKeyframe(_t) {
        const t = this._scaleT(_t);

        const kfi = binarySearchClosest(
            this.keyframes,
            t,
            (a,b) => a - b.t
        );

        // If t falls exactly on a keyframe, return the next one
        const next
            = kfi.length === 1
            ?  this.keyframes[(kfi[0] + 1) % this.keyframes.length]
            : this.keyframes[kfi[1] % this.keyframes.length];

        return { t: this._unscaleT(next.t), v: next.v };
    }

    calc(_t) {
        if (this.keyframes.length === 1) return this.keyframes[0].v;

        // scale into the internal range
        const t = this._scaleT(_t);

        // Find the indices of the keyframes around the t point
        const kfi = binarySearchClosest(
            this.keyframes,
            t,
            (a,b) => a - b.t
        );

        // Only one index returned, t falls exactly on the keyframe.
        if (kfi.length === 1) return this.keyframes[kfi[0]].v;

        // If indices are outside the range of the array, wrap to the opposite end
        const kf = [
            kfi[0] < 0 ? this.keyframes[this.keyframes.length - 1] : this.keyframes[kfi[0]],
            kfi[1] >= this.keyframes.length ? this.keyframes[0] : this.keyframes[kfi[1]]
        ];

        if (kf[0].t === kf[1].t) return kf[0].v;

        // Scale t to the space between the keyframes, and then calculate the
        // interpolated value.
        let wkf = 0, tkf = 0;
        if (kf[0].t > kf[1].t) {
            // wraps
            wkf = (1 - kf[0].t) + kf[1].t;
            if (t < kf[1].t) tkf = (t + (1 - kf[0].t)) / wkf;
            else tkf = (t - kf[0].t) / wkf;

        } else {
            wkf = Math.abs(kf[1].t - kf[0].t);
            tkf = ((t - kf[0].t) * (1 / wkf)) % 1
        }

        return lerp(kf[0].v, kf[1].v, tkf);
    }
}

module.exports = { lerp, KeyframeInterpolator };
