/* eslint-disable react/prop-types */

import React  from 'react';
//import { toJS } from 'mobx';

import { Spinner } from 'react-bootstrap';

import { observer } from 'mobx-react';

import { DataTable } from '../table/DataTable';
import { StringSelect } from '../general/StringSelect';
import { Tag } from '../general/Tag';

import { arrayFilter } from '../utils/filter';

const tableSchema = {
    type: 'object',
    properties: {
        state: { type: 'string', title: 'State' },
        school: { type: 'string', title: 'School' },
        customer: { type: 'string', title: 'Customer' },
        category: { type: 'string', title: 'Category' },
        menuItem: { type: 'string', title: 'Item' },
        allergies: { type: 'string', title: 'Allergies' },
        orderType: { type: 'string', title: 'Order Type' },
        orderDate: { type: 'string', title: 'Date' },
    }
};

const tableFilters = ['school', 'customer', 'menuItem', 'allergies', 'state', 'orderType', 'category'];

const OrderDataGrid = observer(({ orderStore, onSelectChange, onFilterChange, style = {} }) => {
    const [schoolOpts, stateOpts, categoryOpts] = React.useMemo(
        () => {
            const schoolSet = new Set(), stateSet = new Set(), categorySet = new Set();

            for (const order of orderStore.orders) {
                schoolSet.add(order.school);
                stateSet.add(order.state);
                categorySet.add(order.category);
            }

            return [[...schoolSet], [...stateSet], [...categorySet]];
        },
        [orderStore.orders]
    );

    const handleSelectedChange = rows => {
        onSelectChange(rows);
    }

    const handleFilterChange = rows => {
        onFilterChange(rows);
    }

    if (orderStore.loading) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    return (
        <div style={{ width: '100%', ...style }}>
            <DataTable
                schema={tableSchema}
                filterFor={tableFilters}
                onSelect={handleSelectedChange}
                onFilter={handleFilterChange}
                decorate={false}
                borders={false}
                noDataMessage='No Orders'
                data={orderStore.orders}
                uniqueIdField='id'
                headerRowHeight={40}
                customCells={{
                    state: {
                        component: <Tag width='10em' />,
                        filterComponent: <StringSelect options={stateOpts} isClearable={true} />,
                    },
                    school: {
                        filterComponent: <StringSelect options={schoolOpts} isClearable={true} />,
                    },
                    category: {
                        filterComponent: <StringSelect options={categoryOpts} isClearable={true} isMulti={true} />,
                        filterFn: arrayFilter,
                    }
                }}
            />
        </div>
    );
});

const CancelledOrderDataGrid = observer(({ orderStore, style = {} }) => {
    if (orderStore.loading) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    return (
        <div style={{ width: '100%', ...style }}>
            <div style={{ marginLeft: '2.5em', marginRight: '0em' }}>
                <DataTable
                    schema={tableSchema}
                    decorate={false}
                    borders={false}
                    noDataMessage='No Orders'
                    data={orderStore.cancelledOrders}
                    uniqueIdField='id'
                    headerRowHeight={40}
                    customCells={{
                        state: {
                            component: <Tag width='10em' style={{ textDecorationLine: 'line-through' }} />,
                        },
                    }}
                />
            </div>
        </div>
    );
});


export {
    OrderDataGrid,
    CancelledOrderDataGrid,
}
