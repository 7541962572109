import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row      from 'react-bootstrap/Row';

/**
 * A UI component that displays up and down arrows and enables the user
 * to select a direction. The selected direction is indicated by highlighting
 * the appropriate arrow.
 */
class UpDownSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = { downFill: this.props.offCol, upFill: this.props.offCol };

        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);

        this.handleClick = this.handleClick.bind(this);
    }

    handleMouseOver() {
        if (this.props.selected === 'down') {
            this.setState({ upFill: this.props.hoverCol });

        } else {
            this.setState({ downFill: this.props.hoverCol });
        }
    }

    handleMouseOut() {
        this.setState({ downFill: this.props.offCol, upFill: this.props.offCol });
    }

    handleClick() {
        let newOrder = 'down';
        if (this.props.selected === newOrder) newOrder = 'up';

        this.props.onClick(newOrder);
    }

    render() {
        let upFill = this.state.upFill, downFill = this.state.downFill;

        if (this.props.selected === 'down') downFill = this.props.onCol;
        else if (this.props.selected === 'up') upFill = this.props.onCol;

        return (
            <Button
                onClick={this.handleClick}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
                variant='light'
                size='sm'
                className='ps-0 pe-0'
                style={{ width: '18px' }}
            >
                <Container className='p-0'>
                    <Row>
                        <svg width='8' height='8'
                            viewBox='0 0 8 8'
                            alt='An up arrow'
                            style={{margin: 'auto'}}
                        >
                            <path d='M0,7 L4,1 L8,7 z' fill={upFill}/>
                        </svg>
                    </Row><Row>
                        <svg width='8' height='8'
                            viewBox='0 0 8 8'
                            alt='A down arrow'
                            style={{margin: 'auto'}}
                        >
                            <path d='M0,1 L4,7 L8,1 z' fill={downFill}/>
                        </svg>
                    </Row>
                </Container>
            </Button>
        );
    }
}

UpDownSelector.propTypes = {

    /**
     * Which direction is selected, if any.
     */
    selected: PropTypes.oneOf(['up', 'down']),

    /**
     * A function that is called when the user clicks on this component,
     * must have the signature:
     * (String direction) => undefined
     * Where direction is either 'up' or 'down'.
     */
    onClick : PropTypes.func.isRequired,

    /**
     * Colour of the arrows when 'off', i.e. when the value of the selected
     * prop does not match this arrow and the user is not hovering.
     */
    offCol: PropTypes.string.isRequired,

    /**
     * Colour of the arrow which is 'on', i.e. the value of the selected prop
     * matches this arrow.
     */
    onCol: PropTypes.string.isRequired,

    /**
     * Colour of the arrow when the user is hovering.
     */
    hoverCol: PropTypes.string.isRequired,
};

UpDownSelector.defaultProps = {
    offCol   : '#666',
    onCol    : '#17a2b8',
    hoverCol : '#dc3545'
};

export { UpDownSelector };
