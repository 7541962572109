import React from 'react';
import PropTypes from 'prop-types';

import Form     from 'react-bootstrap/Form';

import rowTypes from './rowTypes';
import { insertCellsToTds } from './dataTableUtils';

class DataTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.trRef = React.createRef();
    }

    _renderRowFields(field, idx) {
        const key = `${this.props.rowKey}_${field}`;
        let value = this.props.rowData[field];

        //if (value === undefined) {
        //    throw new Error(`Cannot render DataTable row: field "${field}" has no value in ${JSON.stringify(this.props.rowData)}`);
        //}

        const fieldSchema = this.props.schema.properties[field];

        const colspan = (this.props.skipFields && idx === 0) ? this.props.skipFields : undefined;

        if (this.props.skipFields && idx === 0 ) {
            return <td colSpan={this.props.skipFields}></td>;

        } else if (this.props.skipFields && idx < this.props.skipFields) {
            return null;
        }

        if (
            this.props.customCells
            && this.props.customCells[field]
            && this.props.customCells[field].component
        ) {
            // Note: jsx uses a convention where elements that start with lower case
            // letters, or contain a dash, are treated as HTML.
            const CustomComponent = React.cloneElement(
                this.props.customCells[field].component,
                {
                    value,
                    id: this.props.rowKey,
                    rowData: this.props.rowData,
                    onChange:
                        this.props.onChange
                        ? (id, value) => this.props.onChange(field, value, this.props.rowData)
                        : undefined,
                },
            );

            // eslint-disable-next-line
            return <td key={key} colSpan={colspan}>
                { CustomComponent }
            </td>;
        }

        if (fieldSchema.type === 'array') {
            return (
                <td key={key}>{ value.join(', ') }</td>
            );
        }

        if (value === null) value = '';

        // eslint-disable-next-line
        return <td key={key} colSpan={colspan}>
            { value.toString() }
        </td>;
    }

    render() {
        return (
            <tr ref={this.trRef}>
                {
                    this.props.onSelect
                        ? <td>
                            {
                                this.props.noSelect
                                ? null
                                : <Form.Check
                                    type      = 'checkbox'
                                    id        = { `select_${this.props.rowKey}` }
                                    checked   = { this.props.isSelected || false }
                                    onChange  = { this.props.onSelect }
                                />
                            }
                        </td>
                        : null
                }
                { insertCellsToTds(this.props.insertCells, this.props.rowKey, rowTypes.body, this.props.rowData, this.trRef) }
                { this.props.fields.map((f, i) => this._renderRowFields(f, i)) }
                { insertCellsToTds(this.props.insertCellsAfter, this.props.rowKey, rowTypes.body, this.props.rowData, this.trRef) }
            </tr>
        );
    }
}

DataTableRow.propTypes = {
    rowData : PropTypes.object.isRequired,
    fields  : PropTypes.array.isRequired,

    /**
     * rowKey is provided to external callbacks so must be uniquely identifiable
     * by the model outside of the component (e.g. use the return value of model.uniqueIdField())
     */
    rowKey  : PropTypes.any.isRequired,

    schema  : PropTypes.object.isRequired,
    customCells : PropTypes.object,
    insertCells : PropTypes.func,
    insertCellsAfter : PropTypes.func,
    onSelect    : PropTypes.func,
    isSelected  : PropTypes.bool,
    noSelect    : PropTypes.bool,

    onChange : PropTypes.func,

    skipFields : PropTypes.number.isRequired,
};

DataTableRow.defaultProps = {
    skipFields: 0,
};

export { DataTableRow };
