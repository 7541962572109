function requireWith(withProp, propType) {
    return (props, propName) => {
        if (props[withProp] !== undefined && props[propName] === undefined) {
            return new Error(`${propName} is required with ${withProp}.`);
        }

        if (props[propName] && typeof props[propName] !== propType) {
            return new Error(`${propName} must be of type ${propType}.`);
        }
    }
}

function requireIfOtherNull(withProp, propType) {
    return (props, propName) => {
        if (
            (props[withProp] === undefined || props[withProp] === null)
            && typeof props[propName] !== propType
        ) {
            return new Error(`${propName} is required if ${withProp} is null and must be of type ${propType}.`);
        }
    }
}

/**
 * Custom validator for PropTypes that accepts null as a valid value in
 * addition to whatever other subRequirements are specified.
 *
 * Only makes sense to use with isRequired
 * For Example:
 * nullable(PropTypes.bool).isRequired
 *
 * @param {Function} subRequirement    A PropTypes validator
 */
function nullable(subRequirement) {
    const check = (required, props, key, ...rest) => {
        if (props[key] === null) {
            return null;
        }

        const sub = required ? subRequirement.isRequired : subRequirement;

        return sub(props, key, ...rest);
    };

    const fn = check.bind(null, false);

    fn.isRequired = check.bind(null, true);

    return fn;
}

export { requireWith, requireIfOtherNull, nullable };
