import React from 'react';
import PropTypes from 'prop-types';

function Footer(props) {

    const { render, ...passThroughProps } = props;

    return (
        <>
            {render({ ...passThroughProps })}
            <footer className='footer' >
                <p>© 2024 <a href='https://www.locallunches.com.au/'>Local Lunches</a>. All Rights Reserved.</p>
            </footer>
        </>
    );
}

Footer.propTypes = {

    /**
     * Render prop for rendering the child component with the injected value.
     */
    render: PropTypes.func.isRequired,
};

export { Footer };
