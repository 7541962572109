import React from 'react';
import PropTypes from 'prop-types';

import Form     from 'react-bootstrap/Form';

import rowTypes from './rowTypes';
import { insertCellsToTds } from './dataTableUtils';

class FilterRow extends React.Component {
    constructor(props) {
        super(props);

        const newState = {}
        this.props.fields.forEach( field => {
            newState[field] = props.initialValues ? (props.initialValues[field] || '') : '';
        });
        this.state = newState;

        this._enabledFields = {};
        this.props.filterFor.forEach( field => {
            this._enabledFields[field] = true;
        });

        this.onChange = this.onChange.bind(this);
        this.customOnChange = this.customOnChange.bind(this);
    }

    onChange(evt) {
        const { id, value } = evt.target;
        this.setState({ [id]: value });
        this.props.onChange(id, value);
    }

    customOnChange(id, value) {
        this.setState({ [id]: value });
        this.props.onChange(id, value);
    }

    _renderRowFields(field) {
        const key = `${this.props.rowKey}_${field}`;

        if (!this._enabledFields[field]) {
            return (<td key={key}></td>);
        }

        if (
            this.props.customCells
            && this.props.customCells[field]
            && this.props.customCells[field].filterComponent
        ) {
            const CustomComponent = React.cloneElement(
                this.props.customCells[field].filterComponent,
                {
                    onChange: this.customOnChange,
                    id: field,
                    value: this.state[field] === undefined ? undefined : this.state[field]
                }

            );

            return (
                <td key={key}>
                    { CustomComponent }
                </td>
            );
        }

        return (
            <td key={key}>
                <Form.Control
                    id        = {field}
                    onChange  = {this.onChange}
                    value     = {this.state[field] === undefined ? '' : this.state[field]}
                    style     = {{ width: '100%' }}
                />
            </td>
        );
    }

    render() {
        return (
            <tr>
                { this.props.hasSelectColumn ? <td></td> : null }
                { insertCellsToTds(this.props.insertCells, null, rowTypes.filter) }
                { this.props.fields.map( f => this._renderRowFields(f)) }
                { insertCellsToTds(this.props.insertCellsAfter, null, rowTypes.filter) }
            </tr>
        );
    }
}

FilterRow.propTypes = {
    fields   : PropTypes.array.isRequired,
    initialValues : PropTypes.object,
    rowKey   : PropTypes.string.isRequired,
    schema   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
    filterFor: PropTypes.array,
    customCells: PropTypes.object,
    insertCells: PropTypes.func,
    insertCellsAfter: PropTypes.func,
    hasSelectColumn : PropTypes.bool,
};

export { FilterRow };
