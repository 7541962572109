/* eslint-disable react/prop-types */

import React  from 'react';

import { Spinner } from 'react-bootstrap';

import { observer } from 'mobx-react';

import jsBarcode from 'jsbarcode';

import { orderBarcode } from '../../store/OrderStore';

import "./labels.css";

const schoolNameMap = new Map([
    ['Ninja Kids, Brookvale, NSW', 'Ninja Kids, Brookvale'],
    ['Brookvale Public School Vacation Care', 'Brookvale Public Vacation'],
    ['Lane Cove West Public School, Lane Cove', 'Lane Cove West Public'],
    ['Ku-Ring-Gai Wildflower Garden', 'Ku-Ring-Gai Wildflower'],
    ['Turramurra Public School OOSH', 'Turramurra Public OOSH'],
    ['Northside Montessori Vacation Care', 'Northside Montessori Vacation'],
    ['Northside Montessori School, Pymble', 'Northside Montessori'],
    ['Northside Montessori Pre-Primary, Pymble', 'Northside Montessori Pre-Primary'],
    ['Pendle Hill Public School', 'Pendle Hill Public'],
    ['Killara Public School, Killara', 'Killara Public'],
    ['Gordon East Public School', 'Gordon East Public'],
    ['St Ives Public School, St Ives', 'St Ives Public'],
    ['Turramurra North Public School', 'Turramurra North Public'],
]);

function Label({ order }) {
    const barcodeRef = React.useRef(null);
    const barcode = orderBarcode(order);

    React.useLayoutEffect(() => {
        if (!barcodeRef.current) return;
        jsBarcode(
            barcodeRef.current,
            barcode,
            {
                xmlDocument: window.document,
                height: 20,
                fontSize: 10,
                displayValue: false,
                margin: 6,
            }
        );
    }, []);

    const school = schoolNameMap.get(order.school) || order.school;

    const textLength
        = Math.floor(order.menuItem.length * 0.8)
        + school.length
        + order.customer.length
        + (order.allergies?.length || 0);

    const classSize
        // eslint-disable-next-line no-nested-ternary
        = textLength > 80
        ? (textLength > 100 ? ' very small' : ' small')
        : '';

    return <div className='label'>
        <div className={`order${classSize}`}>
            <p>
                <span className='school'>{school}</span>
                <span className='customer'>{order.customer}</span>
                <span className='orderType'>{order.orderType}</span>
            </p>
            <svg ref={barcodeRef} className='barcode'>
            </svg>
            <p>
                <span className='menuItem'>{order.menuItem}</span>
                {
                    order.allergies?.length > 0
                    ? <><br/><span className='allergies'>Allergies: {order.allergies}</span></>
                    : null
                }
                <span className={classSize}>{barcode}</span>
                <span className={`date${classSize}`}>{order.orderDate}</span>
            </p>
        </div>
    </div>;
}

const PrintLabels = observer(({ orderStore, subset }) => {
    if (orderStore.loading) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    const orders = React.useMemo(() => {
        if (!subset) return orderStore.orders;
        const subsetSet = new Set(subset);

        return orderStore.orders.filter(e => subsetSet.has(e.id.toString()));
    }, [orderStore.orders, subset]);

    return (
        <div className='pageA4'>
            { orders.map(e => <Label key={e.id} order={e} />) }
        </div>
    );
});

export {
    PrintLabels,
}
