function secondsToCTF(seconds) {
    let result = null;

    if (seconds <= 240) result = Math.floor((seconds + 4) / 5);
    else if (seconds <= 3600) result = Math.floor(((seconds - 240 + 29) / 30) + 48);
    else result = Math.floor(((seconds - 3600 + 59) / 60) + 160);

    return Math.min(255, Math.max(0, result));
}

function ctfToSeconds(ctf) {
    if (ctf <= 48) return ctf * 5;

    if (ctf <= 160) return ((ctf - 48) * 30) + 240;

    return ((ctf - 160) * 60) + 3600;
}

function msToCTF(ms) {
    if (ms === 'Infinite') throw new Error('ms cannot be Infinite - use msToCTFWithInfinite instead');
    return secondsToCTF(ms / 1000);
}

function ctfToMs(ctf) {
    return ctfToSeconds(ctf) * 1000;
}

function msToCTFWithInfinite(ms) {
    if (ms === 'Infinite') return 0xFF;
    return secondsToCTF(ms / 1000);
}

function ctfToMsWithInfinite(ctf) {
    if (ctf === 0xFF) return 'Infinite';
    return ctfToSeconds(ctf) * 1000;
}


module.exports = { secondsToCTF, ctfToSeconds, msToCTF, ctfToMs, msToCTFWithInfinite, ctfToMsWithInfinite };
