import React from 'react';
import PropTypes from 'prop-types';

import { UISettings } from '../contexts/UISettings';

class AppContext extends React.Component {
    constructor(props) {
        super(props);

        const d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);

        // Must match the shape of the global context
        this.state = {
            orderDate: d,
            setOrderDate: this.handleSetOrderDate.bind(this),

            errors: [],
            pushError: this.handlePushError.bind(this),
            clearError: this.handleClearError.bind(this),
        };

        this.localStorageName = 'labelScanner_savedState';
    }

    componentDidMount() {
        const savedState = JSON.parse(localStorage.getItem(this.localStorageName) || false);
        console.log('Loaded state from', this.localStorageName);

        if (savedState) {
            this.setState({
                ...savedState,
                orderDate: new Date(savedState.orderDate),
            });
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps) {
        if (this.localStorageName) {
            // eslint-disable-next-line no-unused-vars
            const { ...saveState } = this.state;

            localStorage.setItem(this.localStorageName, JSON.stringify(saveState));
            console.log('Saved state to', this.localStorageName);
        }
    }

    handleSetOrderDate(orderDate) {
        this.setState({ orderDate });
    }

    handlePushError(err) {
        this.setState(prevState => ({ errors: [...prevState.errors, err.toString()] }));
    }

    handleClearError(err) {
        this.setState(prevState => ({ errors: prevState.errors.filter(e => e !== err) }));
    }

    render() {
        return (
            <UISettings.Provider value={this.state}>
                {this.props.children}
            </UISettings.Provider>
        );
    }
}

AppContext.propTypes = {
    children: PropTypes.node,
    gatewayName: PropTypes.string.isRequired,
};

AppContext.defaultProps = {
    gatewayName: '',
};


export { AppContext };
