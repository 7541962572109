/* eslint-disable react/prop-types */

import React  from 'react';
import PropTypes from 'prop-types';

import Modal    from 'react-bootstrap/Modal';
import Button   from 'react-bootstrap/Button';
import Spinner   from 'react-bootstrap/Spinner';
import Alert   from 'react-bootstrap/Alert';

import { UISettings } from '../contexts/UISettings';

import { dateToDMY2 } from '../store/OrderStore';

import { Nav } from './Nav';

import { ReactComponent as WarnSVG } from '../assets/warning_24dp.svg';

const dropzoneStyle = {
    right: 'auto',
    height: '30vh',

    margin: '2em',
    padding: '2em',

    color: '#666',

    border: '2px dashed #aaa',
    borderRadius: '20px',

    backgroundColor: '#fafafa',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

function getDroppedFiles(ev) {
    const files = [];

    if (ev.dataTransfer.items) {
        [...ev.dataTransfer.items].forEach(item => {
            if (item.kind === 'file') {
                files.push(item.getAsFile());
            }
        });

    } else {
        [...ev.dataTransfer.files].forEach(file => {
            files.push(file);
        });
    }

    return files;
}

const preventDefaultHandlerFn = evt => evt.preventDefault();

const UploadInner = ({ api, onError }) => {
    const [showStatus, setShowStatus] = React.useState(false);
    const [uploadResult, setUploadResult] = React.useState(null);

    React.useEffect(
        () => {
            document.body.addEventListener('drop', preventDefaultHandlerFn);
            document.body.addEventListener('dragover', preventDefaultHandlerFn);

            return () => {
                document.body.removeEventListener('drop', preventDefaultHandlerFn);
                document.body.removeEventListener('dragover', preventDefaultHandlerFn);
            };
        }, []
    );

    const handleDrop = async ev => {
        try {
            ev.preventDefault();
            const files = getDroppedFiles(ev);

            if (files.length > 1) {
                throw new Error('More than one file dragged');
            }

            const orderData = JSON.parse(await files[0].text());

            if (orderData.orders.length > 1) {
                setUploadResult(null);
                setShowStatus(true);

                const orderDate = orderData.orders[0].dateStr;

                const res = await api.axios.put(
                    '/orders',
                    {
                        orderDateDMY2: orderDate,
                        orders: orderData.orders,
                    }
                );

                console.log(res);

                setUploadResult({
                    orderDate,
                    result: res.data,
                });
            }

        } catch (err) {
            onError(err);
        }
    };

    return (
        <div>
            <Nav />
            <div id='dropzone'
                style={dropzoneStyle}
                onDrop={handleDrop}
                onDragOver={preventDefaultHandlerFn}
            >
                <p>Drag a file downloaded from Spriggy Schools containing orders here...</p>
            </div>
            <Modal show={showStatus} centered size='lg' enforceFocus={false} >
                <Modal.Header><Modal.Title>Upload Orders</Modal.Title></Modal.Header>
                <Modal.Body>
                    {
                        uploadResult
                        ? <>
                            <p>Uploaded orders for <strong>{uploadResult.orderDate}</strong></p>
                            {
                                dateToDMY2(new Date()) === uploadResult.orderDate
                                ? null
                                : <Alert variant='warning'><WarnSVG />&nbsp;Uploaded orders are not for today</Alert>
                            }
                            <p>Result:
                                <ul>
                                    <li>Existing: <strong>{uploadResult.result.existing}</strong></li>
                                    <li>Added: <strong>{uploadResult.result.added}</strong></li>
                                    <li>Cancelled: <strong>{uploadResult.result.cancelled}</strong></li>
                                </ul>
                            </p>
                        </>
                        : <p><Spinner size='sm' variant='warning' />&nbsp;Uploading...</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='info'
                        onClick={() => setShowStatus(false)}
                    >Dismiss</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const Upload = props => {
    return <UISettings.Consumer>{
        ({ pushError }) => <UploadInner {...props} onError={pushError} />
    }</UISettings.Consumer>;
}

Upload.propTypes = {
    api: PropTypes.object.isRequired,
};

export {
    Upload,
}
