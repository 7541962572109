import React from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SubmitButton } from './general/SubmitButton';

import { useAuth } from '../hooks/auth';
import { useOnMount } from '../hooks/lifecycle';

function Login(props) {
    const authStore = useAuth();
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [input, setInput] = React.useState({ username: '', password: '' });

    // Clear auth prior to first render and without causing
    // a re-render (componentWillMount equivalent).
    useOnMount(() => {
        document.body.classList.add('greyTheme');
        authStore.clearAuthentication();
    });

    const handleCloseError = () => {
        setErrorMessage(null);
        props.history.go(0);
    };

    const handleChange = evt => {
        const { id, value } = evt.target;
        setInput(prev => ({ ...prev, [id]: value }));
    };

    const handleSubmitAsync = async evt => {
        evt.preventDefault();

        const { username, password } = input;

        if (!(username && password)) {
            setErrorMessage('Username and password required');

            return;
        }

        // Trimming trailing spaces is helpful on devices with highly active
        // auto-correct, such as iPads.
        try {
            authStore.authenticateUser(username.trimEnd(), password);
            const user = await authStore.authenticateUser(username.trimEnd(), password);
            if (props.onLoginSuccess) props.onLoginSuccess(user);

            document.body.classList.remove('greyTheme');

            return Boolean(user);

        } catch (err) {
            setErrorMessage(err.message);
        }

        return false;
    };

    const message = props?.location?.state?.message || null;

    return (
        <>
            <Form className='login'>
                <h3 style={{ color: '#efe', fontSize: '2rem', fontWeight: 'bold', textShadow: '#a6d388 2px 2px' }}>Log in</h3>
                { message ? <Alert variant='danger'>{message}</Alert> : null }
                <Form.Group controlId='username' className='mb-3'>
                    <Form.Control placeholder='Enter username'
                        onChange={handleChange}/>
                </Form.Group>
                <Form.Group controlId='password' className='mb-3'>
                    <Form.Control  type='password' placeholder='Enter password'
                        onChange={handleChange}/>
                </Form.Group>
                <SubmitButton
                    confirmSubmit={false}
                    submitOnEnter={true}
                    pendingMessage='Authenticating...'
                    onSubmit={handleSubmitAsync}
                >
                Login
                </SubmitButton>
            </Form>
            <Modal show={errorMessage !== null} onHide={handleCloseError} centered>
                <Modal.Header><Modal.Title>Error.</Modal.Title></Modal.Header>
                <Modal.Body><p>{errorMessage}</p></Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={handleCloseError}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

Login.propTypes = {
    // comes from withRouter HOC
    location: PropTypes.object,
    history: PropTypes.object,

    /**
     * Optional function executed on successful authentication.
     * Has the signature () => {}
     */
    onLoginSuccess: PropTypes.func,
};

export { Login };
