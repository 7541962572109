import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/LoginIcon.png';

function HeaderTall(props) {
    const { render, ...passThroughProps } = props;

    return (
        <>
            <header className='headerTall'>
                <img src={logo} alt='Company logo'/>
            </header>
            {render({ ...passThroughProps })}
        </>
    );
}

HeaderTall.propTypes = {

    /**
     * Render prop for rendering the child component with the injected value.
     */
    render: PropTypes.func.isRequired,
};

export { HeaderTall };
