import React from 'react';
import { createRoot } from 'react-dom/client';

import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './print.css';

import { Main } from './components/Main';

let streamBase = process.env.REACT_APP_STREAM_BASE; // eslint-disable-line no-undef

if (streamBase.match(/^\//u)) {
    // Stream base is relative. Create full path.
    streamBase = `ws://${window.location.host}/${streamBase.replace(/^\//u, '')}`;
}

const api = {
    axios: axios.create({
        baseURL: process.env.REACT_APP_API_BASE, // eslint-disable-line no-undef
        withCredentials: true
    }),
    streams: streamBase
};

class App extends React.Component {
    render() {
        return (
            <Main api={api}/>
        );
    }
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);
