const assert = require('assert');

function nameMap(obj) {
    return new Map(
        Object
        .entries(obj)
        .filter(
            e => typeof e[1] === 'number' || typeof e[1] === 'string'
        )
        .map(e => [e[1], e[0]])
    );
}

function keyToValueMap(obj) {
    return new Map(
        Object
        .entries(obj)
        .filter(
            e => typeof e[1] === 'number' || typeof e[1] === 'string'
        )
        .map(e => [e[0], e[1]])
    );
}

function deepEqual(a, b) {
    try {
        assert.deepStrictEqual(a, b);
        return true;

    } catch (err) {
        if (err.code === 'ERR_ASSERTION' && err.operator === 'deepStrictEqual') return false;
        throw err;
    }
}

module.exports = {
    nameMap,
    keyToValueMap,
    deepEqual,
}
