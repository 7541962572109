// Stops the function passed being run concurrently, but does not sequence
// execution. So if an instance is already running, the second is just skipped.
const notConcurrent = proc => {
    let running = false;

    return (...args) => {
        if (!running) {
            running = (async () => {
                try {
                    return await proc(args);
                } finally {
                    running = false;
                }
            })();
        }

        return running;
    };
}

module.exports = { notConcurrent };
