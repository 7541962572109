/**
 * @module utils
 */

/**
 * Return a new Set containing elements from a that are not in b.
 *
 * @param {(Set|Array)} a     A Set or an array that will be converted to a Set
 * @param {(Set|Array)} b     A Set or an array that will be converted to a Set
 *
 * @returns {Set} A new Set instance that is the difference of a and b
 */
function setDifference(a, b) {
    const _a = a instanceof Array ? new Set(a) : a;
    const _b = b instanceof Array ? new Set(b) : b;

    return new Set([..._a].filter(v => !_b.has(v)));
}

/**
 * Return a new Set that contains all elements from a AND b
 *
 * @param {(Set|Array)} a     A Set or an array that will be converted to a Set
 * @param {(Set|Array)} b     A Set or an array that will be converted to a Set
 *
 * @returns {Set} A new Set instance that is the union of a and b
 */
function setUnion(a, b) {
    const _a = a instanceof Array ? new Set(a) : a;
    const _b = b instanceof Array ? new Set(b) : b;

    return new Set([..._a, ..._b]);
}

/**
 * Return a new Set that contains all elements from a that are in b
 *
 * @param {(Set|Array)} a     A Set or an array that will be converted to a Set
 * @param {(Set|Array)} b     A Set or an array that will be converted to a Set
 *
 * @returns {Set} A new Set instance that is the intersection of a and b
 */
function setIntersection(a, b) {
    const _a = a instanceof Array ? new Set(a) : a;
    const _b = b instanceof Array ? new Set(b) : b;

    return new Set([..._a].filter(v => _b.has(v)));
}

module.exports = { setDifference, setUnion, setIntersection }

