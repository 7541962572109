import assert from 'assert';

/**
 * Compare two arrays.
 * Comparison is performed per element, if the elements match up to the
 * common length, the shorter of the two arrays is considered to be first in
 * sort order.
 *
 * @param {Array} a    The reference event command object
 * @param {Array} b    The comparison event command object
 *
 * @param {Function} compareFn  A comparison function with signature:
 *                              (a,b) => Number
 *                              must return < 0 if a comes before b,
 *                              must return > 0 if b comes before a,
 *                              must return 0 if a and be are equal.
 *                              The default compareFn performs a numeric comparison.
 *
 * @returns A value less than zero if a comes before b in sort order.
 *          A value greater than zero if b comes before a in sort order.
 *          Zero if both are equivalent.
 */
function arrayCompare(a, b, compareFn = (x,y) => x - y) {
    let compare = 0;

    for (let i = 0; i < a.length; i++) {
        if (i >= a.length || i >= b.length) {
            // Elements are the same for as long as the arrays have
            // corresponding indexes. Once one is longer, treat that as coming
            // after
            return a.length - b.length;
        }

        compare = compareFn(a[i], b[i]);
        if (compare !== 0) return compare;
    }

    return 0;
}

/**
 * Compare two arrays of strings. See arrayCompare for rules of comparison.
 *
 * @param {Array} a     The reference array containing only strings
 * @param {Array} b     The comparison array containing only strings
 *
 * @returns A value less than zero if a comes before b in sort order.
 *          A value greater than zero if b comes before a in sort order.
 *          Zero if both are equivalent.
 */
function stringArrayCompare(a, b) {
    return arrayCompare(a, b, (x, y) => x.localeCompare(y));
}

/**
 * Compare two groups by name, when the value given is the group ID.
 *
 * @returns     A value less than zero if a comes before b in sort order
 *              A value greater than zero if b comes before a in sort order
 *              Zero if both are equivalent
 */
function groupNameCompare(groupMap, a, b) {
    const aName = groupMap.get(a);
    const bName = groupMap.get(b);

    return aName.localeCompare(bName);
}


/**
 * Compare two arrays of groups, where each element is an object representing
 * a group, with the shape:
 *   { name }
 *
 * @returns     A value less than zero if a comes before b in sort order
 *              A value greater than zero if b comes before a in sort order
 *              Zero if both are equivalent
 */
function groupArrayCompare(a, b) {
    if (a.length < 1 || b.length < 1) {
        return b.length - a.length;
    }

    const aName = a[0].name;
    const bName = b[0].name;

    return aName.localeCompare(bName);
}

/**
 * Compare two event command objects.
 * Firstly names are compared, then if equal, a comparison of the target
 * groups is performed.
 *
 * @param {Object} a    The reference event command object
 * @param {Object} b    The comparison event command object
 *
 * @returns A value less than zero if a comes before b in sort order.
 *          A value greater than zero if b comes before a in sort order.
 *          Zero if both are equivalent.
 */
function eventCommandCompare(a, b) {
    const nameCompare = a.name.localeCompare(b.name);

    if (nameCompare === 0) {
        return arrayCompare(a.targets, b.targets);
    }

    return nameCompare;
}

/**
 * Compare two values after coercing to string.
 *
 * @param {Object} a    The reference value
 * @param {Object} b    The comparison value
 *
 * @returns A value less than zero if a comes before b in sort order.
 *          A value greater than zero if b comes before a in sort order.
 *          Zero if both are equivalent.
 */
function asStringCompare(a, b) {
    return `${a}`.localeCompare(`${b}`);
}

/**
 * Compare two arc levels. Allowing for the fact that a level may be unknown.
 *
 * @param {Object} arcLevelStream An ArcLevelStream instance. The values for a
 *                                and b will be gotten from this stream if available.
 * @param {Object} a    The reference value to be used if the stream does not hold
 *                      a current value
 * @param {Object} b    The comparison value to be used if the stream does not hold
 *                      a current value
 * @param {string} aId  The ID used to extract the reference value from the stream
 * @param {string} bId  The ID used to extract the comparison value from the stream
 *
 * @returns A value less than zero if a comes before b in sort order.
 *          A value greater than zero if b comes before a in sort order.
 *          Zero if both are equivalent.
 */
function arcLevelCompare(a, b) {
    assert(Number.isInteger(a) || a === null, 'a must be an integer or null');
    assert(Number.isInteger(b) || b === null, 'b must be an integer or null');

    // null is a valid, but treat it as -1 to sort below zero
    const cmpA = a || -1;
    const cmpB = b || -1;

    return cmpA - cmpB;
}

function nodeOnlineStatusCompare(deviceStatusStream, a, b, aId, bId) {
    const currentA = deviceStatusStream.getDeviceStatus(
        typeof aId === 'number' ? aId.toString(16) : parseInt(aId, 10).toString(16)
    );

    const currentB = deviceStatusStream.getDeviceStatus(
        typeof bId === 'number' ? bId.toString(16) : parseInt(bId, 10).toString(16)
    );

    const cmpA = currentA ? currentA.online : a || true;
    const cmpB = currentB ? currentB.online : b || true;

    if (cmpA === cmpB) return 0;
    if (cmpA === true) return -1;

    return 1;

}

export {
    arrayCompare,
    stringArrayCompare,
    groupNameCompare,
    groupArrayCompare,
    eventCommandCompare,
    asStringCompare,
    arcLevelCompare,
    nodeOnlineStatusCompare
};
