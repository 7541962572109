/**
 * @module utils
 */

/**
 * Remove the first occurrence of an item from an array
 *
 * @param {Array}   array   The array
 * @param           item    The first item that matches (using `===`) this item will be removed
 */
function arrayRemove(array, item) {
    const i = array.indexOf(item);
    if (i === -1) return;

    array.splice(i, 1);
}

/**
 * Create an array containing a range of values.
 *
 * @param {Number} min  The starting value
 * @param {Number} max  The ending value (inclusive)
 * @param {Number} step The step between values in the array
 *
 * @returns {Array} An array containing the range of requested values.
 */
function range(min, max, step = 1) {
    // Add one because range is inclusive
    return [...Array(max - min + 1).keys()].filter(v => v % step === 0).map(v => v + min);
}

/**
 * Returns the index of v if found, otherwise returns null.
 * Guaranteed to return the lowest index of v if there are multiple instances.
 *
 * @param {Array}   data    Array of data to search
 * @param {any}     v       Value to search for. Comparisions are performed
 *                          using the given compare function
 *
 * @param {Function} [compare=(a,b) => b - a]
 *                          Function used to compare v against elements
 *                          of the array.
 *
 * @returns {Number} Lowest index of any occurrence of v, or null if v is not found
 */
function binarySearch(data, v, compare = (a,b) => b - a) {
    let l = -1, r = data.length;
    let foundExact = false;

    while(1 + l < r) {
        const mid = l + ((r - l) >> 1);

        const cmp = compare(data[mid], v);

        if (cmp === 0) {
            r = mid;
            foundExact = true;

        } else if (cmp <= 0) {
            r = mid;

        } else {
            l = mid;
        }
    }

    return foundExact ? r : null;
}

/**
 * Returns the index of v (as an array of one element), if found, otherwise
 * returns the indices of the elements that fall on either side of v (as an
 * array of two elements).
 * When two indices are returned, one or the other may be outside the range of
 * the data array if the value v falls outside of the data array.
 *
 * @param {Array}   data    Array of data to search
 * @param {any}     v       Value to search for. Comparisions are performed
 *                          using the given compare function
 *
 * @param {Function} [compare=(a,b) => a - b]
 *                          Function used to compare v against elements
 *                          of the array.
 *
 * @returns {Array} A one or two element array depending on whether an
 *                  exact match was found
 */
function binarySearchClosest(data, v, compare = (a,b) => a - b) {
    let l = 0, r = data.length - 1;

    while(l <= r) {
        const mid = Math.floor((l + r) / 2);
        if (compare(v, data[mid]) < 0) r = mid - 1;
        else if (compare(v, data[mid]) > 0) l = mid + 1;
        else return [mid];
    }

    return [r, l];
}

/**
 * Alternative to Array.prototype.flat() which isn't available on older ES implementations.
 *
 * @param {Array}   a     The array, possibly containing sub arrays, to flatten.
 * @param {number}  depth The maximum depth to flatten to.
 *
 * @returns {Array} The flattened array
 */
function flatArray(a, depth = 1) {
    const r = [];

    for (const e of a) {
        if (e === undefined) continue;
        if (depth > 0 && Array.isArray(e)) r.push(...flatArray(e, depth - 1));
        else r.push(e);
    }

    return r;
}

/**
 * Return a new array with only unique entries
 */
function uniqueArray(a) {
    return [...new Set(a)];
}

function bucket(a, nBuckets) {
    const r = [];

    const perBucket = Math.ceil(a.length / nBuckets);

    for (let i = 0, start = 0; i < nBuckets; i++, start += perBucket) {
        r.push(a.slice(start, start + perBucket));
    }

    return r;
}

function fromEnd(a, n) {
    return a[a.length - (n + 1)];
}

module.exports = {
    arrayRemove,
    range,
    binarySearch,
    binarySearchClosest,
    flatArray,
    uniqueArray,
    bucket,
    fromEnd,
};
