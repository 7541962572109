import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { ReactComponent as HelpSVG } from '../../assets/help-circle.svg';

function HoverForHelp(props) {
    const tooltip = tooltipProps => (
        <Tooltip {...tooltipProps} >
            {props.children}
        </Tooltip>
    )

    return (
        <OverlayTrigger
            placement='auto'
            delay={{ show: 250, hide: 400 }}
            overlay={tooltip}
            trigger={['hover','focus']}
        >
            <HelpSVG style={{
                color: 'rgb(0, 0, 0, 0.5)',
                height: '1rem',
                ...props.style
            }}/>
        </OverlayTrigger>
    );
}

HoverForHelp.propTypes = {
    children: PropTypes.node,
    style   : PropTypes.object
};

export { HoverForHelp };
