/* eslint-disable react/prop-types */

import React  from 'react';

import { Button } from 'react-bootstrap';

import { StringSelect } from '../general/StringSelect';
import { NumberInput } from '../general/NumberInput';
import { NullableTextInput } from '../general/TextInput';

const defaultMenuItemOpts = { options: '', quantity: 1 };

const OrderItemEdit = ({ id, handle, onChange, onDelete, onAdd, items }) => {
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [menuItemOpts, setMenuItemOpts] = React.useState(defaultMenuItemOpts);

    const handleChangeName = (_id, value) => {
        setSelectedItem(value);
        setMenuItemOpts(defaultMenuItemOpts);
        onChange(id, { name: value, ...defaultMenuItemOpts });
    };

    const handleChange = (id, value) => {
        setMenuItemOpts(prev => {
            const o = {
                ...prev,
                [id]: value
            };

            onChange(id, { name: selectedItem, ...o });

            return o;
        });
    };

    return (
        <div className='w-100 mb-2' style={{ display: 'flex' }}>
            <div className='w-100'>
                <div style={{ display: 'flex', width: '100%' }}>
                    <NumberInput
                        id='quantity'
                        onChange={handleChange}
                        value={menuItemOpts.quantity}
                        style={{ width: '4em', marginRight: '0.2em', marginBottom: '0.2em' }}
                    />
                    <StringSelect
                        id='name'
                        options={items}
                        onChange={handleChangeName}
                        value={selectedItem}
                    />
                </div>
                <NullableTextInput id='options' onChange={handleChange} value={menuItemOpts.options} messageOnNull='Options...' />
            </div>
            <div style={{
                display: 'flex',
                padding: '0.2em',
                alignItems: 'center',
                marginLeft: '0.2em',
                marginRight: '1em',
                background: '#f6f6f6',
                borderRadius: '0.375rem'
            }}>
                <Button
                    size='sm'
                    variant='danger'
                    key={`clear_${id}`}
                    onClick={() => onDelete(id, handle)}
                    style={{ height: '24px' }}
                >
                    <svg viewBox='0 0 12 12' width='12px' >
                        <path d='M2,1 h8 v2 h-8 z' fill='white' />
                    </svg>
                </Button>
                <Button
                    size='sm'
                    variant='success'
                    key={`add_${id}`}
                    onClick={() => onAdd(id, handle)}
                    style={{ marginLeft: '4px', height: '24px', paddingTop: '0px' }}
                >
                    <svg viewBox='0 0 12 12' width='12px' >
                        <path d='M2,5 h8 v2 h-8 z' fill='white' />
                        <path d='M5,2 v8 h2 v-8 z' fill='white' />
                    </svg>
                </Button>
            </div>
        </div>
    );
};

class OrderItemLines extends React.Component {
    constructor(props) {
        super(props);

        this.invalidSet = new Set();

        let handle = 1;
        this.lines = props.value.map(e => ({ line: e, handle: handle++ }));

        this.nextHandle = handle;

    }

    handleAdd(i) {
        const lineObj = {
            line: { name: '', option: '' },
            handle: this.nextHandle++
        };
        this.lines.splice(i + 1, 0, lineObj);

        this.invalidSet.add(lineObj.handle);

        this.props.onChange(
            this.props.id,
            this.lines.map(e => e.line),
            this.invalidSet.size > 0
        );
    }

    handleDelete(i) {
        const line = this.lines[i];
        this.lines.splice(i, 1);

        this.invalidSet.delete(line.handle);

        this.props.onChange(
            this.props.id,
            this.lines.map(e => e.line),
            this.invalidSet.size > 0
        );
    }

    handleChange(lineIndex, id, value, invalid) {
        const line = this.lines[lineIndex];

        if (invalid) this.invalidSet.add(line.handle);
        else this.invalidSet.delete(line.handle);

        this.lines[lineIndex].line = value;

        this.props.onChange(
            this.props.id,
            this.lines.map(e => e.line),
            this.invalidSet.size > 0
        );
    }

    renderLines() {
        if (this.lines.length) {
            return this.lines.map(
                (e, i) => <OrderItemEdit
                    key={`pl_handle_${e.handle}`}
                    handle={e.handle}
                    id={`line_${e.handle}`}
                    value={e.line}
                    onChange={this.handleChange.bind(this, i)}
                    onAdd={this.handleAdd.bind(this, i)}
                    onDelete={this.handleDelete.bind(this, i)}
                    items={this.props.itemData.items}
                    itemMap={this.props.itemData.itemMap}
                />
            );
        }

        return <tr><td style={{ border: 'none' }}><Button
            variant='success'
            key={`add_first`}
            onClick={this.handleAdd.bind(this, 0)}
            style={{ display: 'flex' }}
        >
            <svg viewBox='0 0 12 12' width='20px' style={{ marginRight: '0.5rem' }} >
                <path d='M2,5 h8 v2 h-8 z' fill='#eee'/>
                <path d='M5,2 v8 h2 v-8 z' fill='#eee' />
            </svg>
            Add Menu Item
        </Button></td></tr>;
    }

    render() {
        return (
            <div className='w-100'>
                {this.renderLines()}
            </div>
        );
    }
}

export { OrderItemLines }
