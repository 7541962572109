import React from 'react';

import { useAuth } from '../../hooks/auth';

function withAuth(Sub) {
    function WithAuth(props) {
        const authStore = useAuth();

        return <Sub {...props} authStore={authStore} />;
    }

    return WithAuth;
}

export { withAuth };
