import React from 'react';
import PropTypes from 'prop-types';

import Button   from 'react-bootstrap/Button';
import Spinner  from 'react-bootstrap/Spinner';
import Modal    from 'react-bootstrap/Modal';

const buttonState = Object.freeze({
    waiting: Symbol('waiting'),
    confirm: Symbol('confirm'),
    pending: Symbol('pending'),
    success: Symbol('success'),
    failure: Symbol('failure')
});

/**
 * This SubmitButton adds a confirmation dialog and feedback functionality to
 * the basic Bootstrap Button.
 * The button may be in one of a number of states:
 * waiting: Idle state, waiting for the user to submit,
 * confirm: Shows a modal dialog to confirm the action,
 * pending: User has confirmed the action, the onSubmit function passed in via
 *          props has been called and has not yet returned.,
 * success: The onSubmit function returned true,
 * failure: The onSubmit function returned false
 */
class SubmitButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { mode: buttonState.waiting, width: null };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);

        this.buttonRef = React.createRef();

        this.timeoutFn = null;

        this.buttonYRef = React.createRef();
        this.buttonYWidth = null;
    }

    componentDidMount() {
        if (this.buttonRef.current && !this.buttonWidth) {
            this.setState({ width: Math.ceil(this.buttonRef.current.offsetWidth + 1) });
        }
    }

    componentWillUnmount() {
        if (this.timeoutFn) clearTimeout(this.timeoutFn);
        this.unmounted = true;
    }

    componentDidUpdate() {
        // Fix the width of the button that shows the animation / changes message
        if (this.buttonYWidth) return;

        const yButton = this.buttonYRef.current;
        if (!yButton) return;

        this.buttonYWidth = getComputedStyle(yButton).width;
    }

    async handleSubmit(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({ mode: buttonState.confirm });
    }

    async handleConfirmSubmit(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        //if (this.props.confirmSubmit) this.setState({ mode: buttonState.pending });
        this.setState({ mode: buttonState.pending });

        this.props.onSubmit(evt)
        .then( result => {
            // When the user submits, actions can occur in the parent UI element
            // that cause the SubmitButton to be unmounted. In that case, we
            // do not want to do anything further.
            if (this.unmounted) return;

            this.setState({ mode: result ? buttonState.success : buttonState.failure });

            if (!isNaN(this.props.messageTime)) {
                if (this.timeoutFn) clearTimeout(this.timeoutFn);
                this.timeoutFn = setTimeout(
                    () => this.setState({ mode: buttonState.waiting }),
                    this.props.messageTime
                );
            }
        });
    }

    async handleCancelSubmit(evt) {
        evt.stopPropagation();
        this.setState({ mode: buttonState.waiting});
    }

    // eslint-disable-next-line complexity
    render() {
        let variant = null, message = null;

        switch (this.state.mode) {
            case buttonState.success:
                message = this.props.stateMessages ? this.props.successMessage : this.props.children;
                variant = 'success';
                break;

            case buttonState.failure:
                message = this.props.stateMessages ? this.props.failMessage : this.props.children;
                variant = 'danger';
                break;

            case buttonState.pending:
                if (this.props.confirmSubmit === false) {
                    message = this.props.stateMessages ? this.props.pendingMessage : this.props.children;
                    variant = this.props.variant;
                    break;
                }
                // Else fall through

            case buttonState.confirm:
            default:
                message = this.props.children;
                variant = this.props.variant;
                break;
        }

        const confirmMessage = this.props.stateMessages && this.state.mode === buttonState.pending ? <Spinner size='sm' animation='grow' variant='light' /> : 'Yes';

        const tooltipProps = this.props.tooltip ? { 'data-toggle': 'tooltip', title: this.props.tooltip } : {};

        const style = {
            width: this.state.width || undefined,
            textOverflow: 'ellipsis',
            ...this.props.style
        };

        return (
            <>
                <Button
                    ref={this.buttonRef}
                    type = { this.props.submitOnEnter ? 'submit' : undefined }
                    size = { this.props.size }
                    variant  = { variant }
                    disabled = { this.props.disabled }
                    onClick  = { this.props.confirmSubmit ? this.handleSubmit : this.handleConfirmSubmit }
                    className = { this.props.className }
                    {...this.props.buttonProps}
                    {...tooltipProps}
                    style = {style}
                >
                    { message }
                </Button>
                <Modal
                    show={
                        this.state.mode === buttonState.confirm
                        || (this.state.mode === buttonState.pending && this.props.confirmSubmit)
                    }
                    onHide={this.handleCancelSubmit}
                    centered
                >
                    <Modal.Body>{this.props.body}</Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={this.handleCancelSubmit}>No</Button>
                        <Button
                            ref={this.buttonYRef}
                            variant='info'
                            onClick={this.handleConfirmSubmit}
                            style = { this.buttonYWidth ? { width: this.buttonYWidth } : null}
                        >
                            {confirmMessage}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

SubmitButton.propTypes = {

    /**
     * Classes to be applied to the underlying Button component.
     */
    className : PropTypes.string,

    /**
     * Additional props that can be passed onto the Bootstrap Button.
     */
    buttonProps: PropTypes.object,

    /**
     * Whether to show a submit confirmation modal dialog or not.
     */
    confirmSubmit: PropTypes.bool.isRequired,

    /**
     * Callback executed when Submission is confirmed.
     * Must have the signature:
     * async (Event event) => Boolean
     * Return true on success and false on failure - these states will
     * be indicated in the SubmitButton.
     */
    onSubmit : PropTypes.func.isRequired,

    /**
     * The variant used for the waiting state.
     */
    variant  : PropTypes.string.isRequired,

    /**
     * Set the size of the control from one of a number of variants provided
     * by the underlying Bootstrap Button. i.e. 'sm', 'lg'.
     */
    size : PropTypes.string,

    /**
     * Indicate state changes by changing the message displayed in the button.
     * The message for the waiting state is always as set by the user.
     */
    stateMessages : PropTypes.bool,

    /**
     * Specify a specific message to use while the submission is pending.
     * If not specified. The default value will be used.
     * Only valid if props.stateMessages is true.
     */
    pendingMessage: PropTypes.string.isRequired,

    /**
     * Specify a specific message to use when the submission is a success.
     * If not specified. The default value will be used.
     * Only valid if props.stateMessages is true.
     */
    successMessage: PropTypes.string.isRequired,

    /**
     * Specify a specific message to use if the submission fails.
     * If not specified. The default value will be used.
     * Only valid if props.stateMessages is true.
     */
    failMessage: PropTypes.string.isRequired,

    /**
     * Whether the button is disabled or not.
     */
    disabled : PropTypes.bool,

    /**
     * The number of milliseconds messages will display for.
     */
    messageTime : PropTypes.number.isRequired,

    /**
     * The child elements displayed within the Button when in the confirm state.
     */
    children: PropTypes.any,

    /**
     * The elements displayed within the body of the dialog.
     */
    body: PropTypes.any,

    /**
     * An optional string that specifies a tooltip for the button
     */
    tooltip: PropTypes.string,

    submitOnEnter: PropTypes.bool,

    style: PropTypes.object.isRequired,
};

SubmitButton.defaultProps = {
    variant  : 'info',
    stateMessages : true,
    pendingMessage: 'Working...',
    successMessage: 'Success!',
    failMessage: 'Failed',
    disabled : false,
    messageTime : 1500,
    confirmSubmit: true,
    body: <p>Are you sure you want to apply these changes?</p>,
    submitOnEnter: false,
    style: {},
};

export { SubmitButton };
