function numberToUInt32Array(n) {
    return new Uint32Array([
        // / 0x0100000000 returns the high 32 bits as the whole part and the lower 32 bits as the fractional part
        (n / 0x0100000000) & 0xffffffff,

        // % 0x0100000000 returns the low 32 bits
        n % 0x0100000000
    ]);
}

module.exports = {
    numberToUInt32Array,
}
