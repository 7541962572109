import React, { createContext, useContext, useMemo } from 'react';
import { AuthStore } from '../authStore/authStore';

const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children, api }) => {
    const [checked, setChecked] = React.useState(false);

    const authStore = useMemo(
        () => new AuthStore(api),
        [api]
    );

    React.useEffect(() => {
        // Attempt to refresh with any existing cookie
        authStore.refreshUserAsync().then(() => setChecked(true));

        return () => authStore.kill();
    }, [authStore]);

    if (!checked) {
        console.log('Hold while I check auth...');

        return null;
    }

    return <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
