import assert from 'assert';

// Note that all channel ranges are 0..254 rather than 0..255

function hsvToRGB(hsv) {
    assert(typeof hsv.h === 'number', 'h must be a number');
    assert(typeof hsv.s === 'number', 's must be a number');
    assert(typeof hsv.v === 'number', 'v must be a number');

    assert(hsv.h <= 1 && hsv.s <= 1 && hsv.v <= 1, 'All components of hsv must be in the range [0..1]');

    if (hsv.s === 0) {
        const col = Math.floor(hsv.v * 254);

        return { r: col, g: col, b: col };
    }

    const hMul = hsv.h * 6 % 6;

    const i = Math.floor(hMul);

    const v1 = hsv.v * (1 - hsv.s);
    const v2 = hsv.v * (1 - (hsv.s * (hMul - i)));
    const v3 = hsv.v * (1 - (hsv.s * (1 - (hMul - i))));

    const rgb = {};

    if (i === 0) {
        rgb.r = hsv.v;
        rgb.g = v3;
        rgb.b = v1;

    } else if (i === 1) {
        rgb.r = v2;
        rgb.g = hsv.v;
        rgb.b = v1;

    } else if (i === 2) {
        rgb.r = v1;
        rgb.g = hsv.v;
        rgb.b = v3;

    } else if (i === 3) {
        rgb.r = v1;
        rgb.g = v2;
        rgb.b = hsv.v;

    } else if (i === 4) {
        rgb.r = v3;
        rgb.g = v1;
        rgb.b = hsv.v;

    } else {
        rgb.r = hsv.v;
        rgb.g = v1;
        rgb.b = v2;
    }

    rgb.r = Math.floor(rgb.r * 254);
    rgb.g = Math.floor(rgb.g * 254);
    rgb.b = Math.floor(rgb.b * 254);

    return rgb;
}

function rgbToHSV(rgb) {
    const { r, g, b } = rgb;

    assert(typeof r === 'number', 'r must be a number');
    assert(typeof g === 'number', 'b must be a number');
    assert(typeof b === 'number', 'g must be a number');

    const nr = r / 254;
    const ng = g / 254;
    const nb = b / 254;

    const minN = Math.min(nr, ng, nb);
    const maxN = Math.max(nr, ng, nb);
    const dN = maxN - minN;

    const hsv = { v: maxN };

    if (dN === 0) {
        hsv.h = 0;
        hsv.s = 0;

    } else {
        hsv.s = dN / maxN;

        const halfDN = dN / 2;

        const dR = (((maxN - nr) / 6) + halfDN) / dN
        const dG = (((maxN - ng) / 6) + halfDN) / dN
        const dB = (((maxN - nb) / 6) + halfDN) / dN

        if (nr === maxN) hsv.h = dB - dG;
        else if (ng === maxN) hsv.h = (1 / 3) + dR - dB;
        else if (nb === maxN) hsv.h = (2 / 3) + dG - dR;

        if (hsv.h < 0) hsv.h += 1;
        if (hsv.h > 1) hsv.h -= 1;
    }

    return hsv;
}

function kelvinToRgb(k) {
    const temp = k / 100;

    const rgb = {};

    if (temp <= 66) {
        rgb.r = 255

        rgb.g = Math.max(
            0,
            Math.min(
                255,
                (99.4708025861 * Math.log(temp)) - 161.1195681661,
            )
        );

    } else {
        rgb.r = Math.max(
            0,
            Math.min(
                255,
                329.698727446 * Math.pow(temp - 60, -0.1332047592)
            )
        );

        rgb.g = Math.max(
            0,
            Math.min(
                255,
                288.1221695283 * Math.pow(temp - 60, -0.0755148492)
            )
        );
    }

    if (temp >= 66) {
        rgb.b = 255;

    } else {
        rgb.b = Math.max(
            0,
            Math.min(
                255,
                (138.5177312231 * Math.log(temp - 10)) - 305.0447927307
            )
        );
    }

    return rgb;
}

function rgbToHexString(rgb) {
    assert(Number.isInteger(rgb.r) && Number.isInteger(rgb.g) && Number.isInteger(rgb.b));

    const r = rgb.r.toString(16).padStart(2, '0');
    const g = rgb.g.toString(16).padStart(2, '0');
    const b = rgb.b.toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
}

function hexStringToRgb(str) {
    const colStr = str.startsWith('#') ? str.slice(1) : str;

    assert(colStr.length === 6, 'hexadecimal portion of string must be six characterrs');

    return {
        r: parseInt(colStr.slice(0, 2), 16) / 255 * 254,
        g: parseInt(colStr.slice(2, 4), 16) / 255 * 254,
        b: parseInt(colStr.slice(4, 6), 16) / 255 * 254,
    };
}

export { hsvToRGB, rgbToHSV, kelvinToRgb, rgbToHexString, hexStringToRgb };
