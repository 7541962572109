import React from 'react';

import PropTypes from 'prop-types';

import Form     from 'react-bootstrap/Form';
import Row      from 'react-bootstrap/Row';
import Col      from 'react-bootstrap/Col';
import Badge    from 'react-bootstrap/Badge';

import { HoverForHelp } from './HoverForHelp';

function fixedMessage(msg) {
    if (msg === 'must NOT have fewer than 1 characters') return 'Must not be blank'
    else if (msg === 'must be number') return 'Must be a number';
    return msg;
}

class FormElement extends React.Component {
    render() {
        const { isRequired, errors, label, render, className, ...passThroughProps } = this.props;

        const textLocation = this.props.labelOnRight ? 'text-start' : 'text-end';

        const cols = [
            label
            ? <Form.Label
                column sm={4 + (this.props.labelWidthModifier || 0)}
                className={textLocation}
                key={`${this.props.id}_formelement_label`}
            >
                {label}
                {
                    this.props.help
                    ? <span className='ms-1'><HoverForHelp>{this.props.help}</HoverForHelp></span>
                    : null
                }
            </Form.Label>
            : <Col sm={4}></Col>,
            <Col key={`${this.props.id}_formelement_form`}>
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                    {
                        render({ ...passThroughProps })
                    }
                </div>
                {isRequired ? <Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback> : null}
                <div style={{fontSize: '0.8rem', marginTop: '-0.38em', marginBottom: '-1em'}}>
                    {
                        errors && errors.length > 0
                        ? errors.map( e => <Badge bg='danger' key={e} style={{ marginRight: '2px'}}>{fixedMessage(e)}</Badge> )
                        : <Badge style={{ visibility: 'hidden' }}>OK</Badge>
                    }
                </div>
            </Col>
        ];

        return (
            <Form.Group as={Row} controlId={this.props.id} style={{display: 'flex', ...(this.props.style || {}) }}
                className={`mb-3 ${className} ${errors && errors.length > 0 ? 'text-danger' : 'text-dark'}`}>
                {
                    this.props.labelOnRight
                    ? cols.reverse()
                    : cols
                }
            </Form.Group>
        );
    }
}

FormElement.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    isRequired: PropTypes.bool,
    errors: PropTypes.array,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    label: PropTypes.string,
    labelOnRight: PropTypes.bool.isRequired,
    help: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    render: PropTypes.func.isRequired,
    labelWidthModifier: PropTypes.number,
    className : PropTypes.string
};

FormElement.defaultProps = {
    labelOnRight: false,
};

export { FormElement };

