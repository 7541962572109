import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

const styles = {
    container: base => ({ ...base, width: '100%' }),
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};

function RoleSelect(props) {
    const { optionsMap, options } = React.useMemo(
        () => {
            const options = [], optionsMap = new Map();

            props.options.forEach(v => {
                const option = { value: v, label: v, isFixed: v === 'user' };
                options.push(option);
                optionsMap.set(v, option)
            });

            return { options, optionsMap };
        },
        [props.options]
    );


    const onChange = (value, actionMeta) => {
        let newValue = null;

        // eslint-disable-next-line default-case
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) return;
                break;

            case 'clear':
                newValue = options.filter(v => v.isFixed);
                break;
        }

        props.onChange(props.id, (newValue || value).map(e => e.value));
    };

    const value = props.value.map(v => optionsMap.get(v));

    return <Select
        id={props.id}
        styles={styles}
        options={options}
        value={value}
        onChange={onChange}

        isMulti
    />
}

RoleSelect.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
}


export { RoleSelect }
