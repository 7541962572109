import React from 'react';

import { observer } from 'mobx-react';

import { Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

// This private route implementation uses a mobx store to hold authentication
// state. Authentication and refresh is triggered via functions on the store,
// the observed user details are updated which triggers the reactive flow
// to block the user if required.
// Could also add a useAuth hook that returns the store.. undecided

// I think it is better to have PrivateRoute as a child of Route, assuming
// if the route is not valid then the PrivateRoute component is not mounted,
// which works out better when it is reacting to observed changes in auth.

const PrivateRoute = observer(({ children }) => {
    const authStore = useAuth();

    const isAuthenticated = Boolean(authStore.user);

    console.log('PrivateRoute', isAuthenticated);

    if (isAuthenticated) {
        return children;
    }

    return <Redirect to={ {pathname: '/login',  state:{ message: authStore.noAuthReason() }} }/>
});

export { PrivateRoute };
