class StatusModel {
    constructor(api) {
        this.axios = api.axios;
    }

    async get() {
        await this.axios.get('/status')
        .then( res => {
            this.data = res.data;
        })
        .catch( err => {
            console.log('updateFromSource get error:', err);
            throw err;
        });

        return this.data;
    }
}

export { StatusModel };
