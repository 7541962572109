import React from 'react';

export function useOnMount(callback) {
    const mounted = React.useRef(false);

    // eslint-disable-next-line callback-return
    if (!mounted.current) callback();

    React.useEffect(
        () => {
            mounted.current = true
        }, []
    );
}
